<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Swal from "sweetalert2";
import moment from "moment";

/**
 * Advanced table component
 */
export default {
    components: { Layout, PageHeader, Swal },
    data() {
        return {
            title: "Advanced Table",
            items: [
                {
                text: "Tables",
                href: "/"
                },
                {
                text: "Advanced",
                active: true
                }
            ],
            isLoading:false,
            recibos:[],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "age",
            sortDesc: false,
            fields: [
                { key: "id", label:"Nº", sortable: true },
                { key: "rut", label:"Rut", sortable: true },
                { key: "recibide", label:"Recibí de", sortable: true },
                { key: "suma", label:"Total", sortable: true },
                { key: "tipopago", label:"Tipo de Pago", sortable: true },
                { key: "asignacion", label:"Asignación", sortable: true },
                { key: "accion", label:"Acción", sortable: true }
            ],
            datos:{
                fecha:'',
                rut:'',
                recibide:'',
                facturas:[],
                asignacion:'no especifico',
                suma:'',
                pago:[],
                mediopago:{
                    n:'',
                    banco:'',
                    fecha:'',
                    tipotarjeta:'',
                    tipowebpay:'',
                }
            },
            datos_editar:{
                id:'',
                estado:'',
                fecha:'',
                rut:'',
                recibide:'',
                facturas:[],
                suma:'',
                pago:[],
                mediopago:{
                    n:'',
                    banco:'',
                    fecha:'',
                    tipotarjeta:'',
                    tipowebpay:''
                }
            },
            contabilidad:{
                id:'',
                fechacontable:'',
                ncontable:'',
                boolfecha:false,
                booln:false,
                asignacion:'',
                mismos:[],
                arreglo:[],
                selectAll: false,
            },
            recibos2:[],
            recibos3:[],
            totalRows2: 1,
            currentPage2: 1,
            perPage2: 10,
            pageOptions2: [10, 25, 50, 100],
            filter2: null,
            filterOn2: [],
            sortBy2: "age",
            sortDesc2: false,
            fields2: [
                { key: "id", label:"Nº", sortable: true },
                { key: "rut", label:"Rut", sortable: true },
                { key: "recibide", label:"Recibí de", sortable: true },
                { key: "suma", label:"Total", sortable: true },
                { key: "tipopago", label:"Tipo de Pago", sortable: true },
                { key: "asignacion", label:"Asignación", sortable: true },
                { key: "accion", label:"Acción", sortable: true }
            ],
            info:{},
            observacion:{},
            obs:{},
            options:[
                { text:'Vigentes',value:'vigente' },
                { text:'No Vigentes',value:'novigente' },
                { text:'Todos',value:'todos' },
            ],
            selected:'',
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.recibos.length;
        },
        rows2() {
            return this.recibos2.length;
        },
        sumaTotal() {
            // Sumar todos los valores de las facturas
            this.datos.suma = this.datos.facturas.reduce((total, factura) => total + parseInt(factura.valor || 0), 0);
            return this.datos.suma;
        },
        sumaTotal_editar() {
            // Sumar todos los valores de las facturas
            this.datos_editar.suma = this.datos_editar.facturas.reduce((total, factura) => total + parseInt(factura.valor || 0), 0);
            return this.datos_editar.suma;
        },
        suma(){
            return this.contabilidad.arreglo.reduce((total, id) => {
                const recibo = this.contabilidad.mismos.find(r => r.id === id);
                return recibo ? total + parseInt(recibo.suma) : total;
            }, 0);
        },
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
        this.totalRows2 = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        fecha(fechahora){
            var orden = moment(fechahora).format('DD-MM-YYYY');
            return orden
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        onFiltered2(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows2 = filteredItems.length;
            this.currentPage2 = 1;
        },
        modal_recibo(){
            this.datos.fecha= moment(new Date).format('YYYY-MM-DD');
            this.$refs['modal-recibo'].show()
        },
        factura(){
            this.datos.facturas.push({ concepto: '', valor: 0, fecha:'' });
        },
        factura_editar(){
            this.datos_editar.facturas.push({ concepto: '', valor: 0, fecha:'' });
        },
        eliminar(index){
            this.datos.facturas.splice(index, 1);
        },
        eliminar_editar(index){
            this.datos_editar.facturas.splice(index, 1);
        },
        msg(data){
            Swal.fire({
                //position: "top-end",
                icon: "error",
                title: "Falta campo por completar",
                text: data,
                showConfirmButton: false,
                timer: 1500
            });
        },
        validar(){
            if(this.datos.fecha==''){
                this.msg('Fecha');
                return
            }
            if(this.datos.rut==''){
                this.msg('Rut');
                return
            }
            if(this.datos.recibide==''){
                this.msg('Recibí de ');
                return
            }
            if(this.datos.facturas.length==0){
                this.msg('Concepto de ');
                return
            }
            if(this.datos.pago==''){
                this.msg('Forma de pago ');
                return
            }
            this.guardar_recibos();
        },
        cancelar_recibos(){
            this.datos.fecha='';
            this.datos.rut='';
            this.datos.recibide='';
            this.datos.facturas=[];
            this.datos.asignacion='no especifico';
            this.datos.suma='';
            this.datos.pago=[];
            this.datos.mediopago.n='';
            this.datos.mediopago.banco='';
            this.datos.mediopago.fecha='';
            this.datos.mediopago.tipotarjeta='';
            this.datos.mediopago.tipowebpay='';
        },
        guardar_recibos(){
            let formData = new FormData();
            formData.append('fecha', this.datos.fecha);
            formData.append('rut', this.datos.rut);
            formData.append('recibide', this.datos.recibide);
            formData.append('asignacion', this.datos.asignacion);
            formData.append('facturas', JSON.stringify(this.datos.facturas));
            formData.append('suma', this.datos.suma);
            formData.append('pago', JSON.stringify(this.datos.pago));
            formData.append('mediopago', JSON.stringify(this.datos.mediopago));
            formData.append('creado_user_id', this.user.id);
            axios.post('/api/crear_recibos',formData).then(res=>{
                if(res.data.estado){
                    Swal.fire({
                        //position: "top-end",
                        icon: "success",
                        title: "Se ha creado el nuevo recibo con éxito",
                        //text: data,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.datos.fecha='';
                    this.datos.rut='';
                    this.datos.recibide='';
                    this.datos.facturas=[];
                    this.datos.asignacion='no especifico';
                    this.datos.suma='';
                    this.datos.pago='';
                    this.datos.mediopago.n='';
                    this.datos.mediopago.banco='';
                    this.datos.mediopago.fecha='';
                    this.datos.mediopago.tipotarjeta='';
                    this.datos.mediopago.tipowebpay='';
                    this.actualizar();
                }
            });
        },
        editar_modal(item){
            this.datos_editar=item;
            //this.datos_editar.facturas = JSON.parse(item.facturas);
            //this.datos_editar.mediopago = JSON.parse(item.mediopago);
            this.$refs['modal-recibo-editar'].show()
        },
        editar_recibos(){
            let formData = new FormData();
            formData.append('id', this.datos_editar.id);
            formData.append('fecha', this.datos_editar.fecha);
            formData.append('estado', this.datos_editar.estado);
            formData.append('rut', this.datos_editar.rut);
            formData.append('recibide', this.datos_editar.recibide);
            formData.append('asignacion', this.datos_editar.asignacion);
            formData.append('facturas', JSON.stringify(this.datos_editar.facturas));
            formData.append('suma', this.datos_editar.suma);
            formData.append('pago', this.datos_editar.pago);
            formData.append('mediopago', JSON.stringify(this.datos_editar.mediopago));
            formData.append('editado_user_id', this.user.id);
            axios.post('/api/editar_recibos',formData).then(res=>{
                if(res.data.estado){
                    Swal.fire({
                        //position: "top-end",
                        icon: "success",
                        title: "Se ha editado con éxito",
                        //text: data,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.actualizar();
                }
            });
        },
        actualizar(){
            axios.get('/api/get_recibos').then(res=>{
                this.recibos=res.data.recibos.filter(recibo=>recibo.ncontable==null && recibo.estado=='vigente');
                this.recibos2=res.data.recibos.filter(recibo=>recibo.ncontable!=null || recibo.estado=='eliminado');
                this.recibos3=res.data.recibos.filter(recibo=>recibo.ncontable!=null || recibo.estado=='eliminado');
            });
        },
        contabilidad_modal(item){
            this.contabilidad.arreglo=[];
            this.contabilidad.id = item.id;
            this.contabilidad.fechacontable = item.fechacontable;
            this.contabilidad.ncontable = item.ncontable;
            this.contabilidad.asignacion = item.asignacion;
            if (item.ncontable!=null) {
                this.contabilidad.boolfecha=true;
                this.contabilidad.booln=true;
            }
            //this.contabilidad.arreglo.push(item.id);
            //let hoy = moment(new Date).format('YYYY-MM-DD');
            this.contabilidad.mismos=this.recibos.filter(recibo => recibo.fecha === item.fecha && recibo.asignacion===item.asignacion)
            this.contabilidad.selectAll=true;
            this.toggleAll();
            this.$refs['modal-contabilidad'].show()
        },
        validacion_contabilidad(){
            if(this.contabilidad.fechacontable=='' || this.contabilidad.fechacontable==null){
                this.msg('Fecha Contable');
                return
            }
            if(this.contabilidad.ncontable=='' || this.contabilidad.ncontable==null){
                this.msg('Número Contable');
                return
            }
            this.guardar_contabilidad();
        },
        guardar_contabilidad(){
            let formData = new FormData();
            formData.append('id', this.contabilidad.id);
            formData.append('fechacontable', this.contabilidad.fechacontable);
            formData.append('ncontable', this.contabilidad.ncontable);
            formData.append('arreglo', this.contabilidad.arreglo);
            formData.append('editado_user_id', this.user.id);
            axios.post('/api/guardar_contabilidad',formData).then(res=>{
                if(res.data.estado){
                    Swal.fire({
                        //position: "top-end",
                        icon: "success",
                        title: "Se ha guardado con éxito los datos",
                        //text: data,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.contabilidad.id='';
                    this.contabilidad.fechacontable='';
                    this.contabilidad.ncontable='';
                    this.contabilidad.boolfecha=false;
                    this.contabilidad.booln=false;
                    this.contabilidad.asignacion='';
                    this.contabilidad.mismos=[];
                    this.contabilidad.arreglo=[];
                    this.contabilidad.selectAll= false;
                    this.actualizar();
                }
            });
        },
        modal_observacion(item){
            this.observacion=item;
            this.$refs['modal-observacion'].show()
        },
        eliminar_recibo(){
            let item = this.observacion;
            let formData = new FormData();
            formData.append('id', item.id);
            formData.append('observacion', item.observacion);
            formData.append('editado_user_id', this.user.id);
            axios.post('/api/eliminar_recibo',formData).then(res=>{
                if(res.data.estado){
                    Swal.fire({
                        //position: "top-end",
                        icon: "success",
                        title: "El recibo se ha eliminado",
                        //text: data,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.actualizar();
                }
            });
        },
        toggleAll() {
            if (this.contabilidad.selectAll) {
                // Selecciona todos los IDs de los recibos
                this.contabilidad.arreglo = this.contabilidad.mismos.map(recibo => recibo.id);
            } else {
                // Deselecciona todo
                this.contabilidad.arreglo = [];
                this.contabilidad.arreglo.push(this.contabilidad.id);
            }
        },
        checkIfAllSelected() {
            if (this.contabilidad.arreglo.length === this.contabilidad.mismos.length) {
                this.contabilidad.selectAll = true;
            } else {
                this.contabilidad.selectAll = false;
            }
        },
        ver_modal(item){
            this.info=item;
            this.$refs['modal-ver'].show();
        },
        capitalizarPrimeraLetra(text) {
            if (!text) return '';
            return text.charAt(0).toUpperCase() + text.slice(1);
        },
        modal_obs(item){
            this.obs=item;
            this.$refs['modal-obs'].show()
        },
        obser(){
            let item = this.obs;
            let formData = new FormData();
            formData.append('id', item.id);
            formData.append('observacion', item.observacion);
            formData.append('editado_user_id', this.user.id);
            axios.post('/api/anular_recibo',formData).then(res=>{
                if(res.data.estado){
                    Swal.fire({
                        //position: "top-end",
                        icon: "success",
                        title: "El recibo se ha anulado",
                        //text: data,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.actualizar();
                }
            });
        },
        filtro_tabla(){
            if (this.selected=='vigente') {
                this.recibos2=this.recibos3.filter(recibo=>recibo.estado=='vigente');
            }else if(this.selected=='novigente'){
                this.recibos2=this.recibos3.filter(recibo=>recibo.estado=='eliminado' || recibo.estado=='nulo');
            }else if(this.selected=='todos'){
                this.recibos2=this.recibos3;
            }
        }
    },
    created(){
        //this.isLoading=true
        this.actualizar();
        this.factura();
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="text-center">
                <loading-message  v-if="isLoading" />
            </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Recibos Electrónicos</h4>
                        <div class="row mt-4">
                            <div class="offset-md-6 col-md-6 text-right">
                                <b-button variant="primary" size="sm" @click="modal_recibo()">Agregar Recibo</b-button>
                                <b-modal ref="modal-recibo" title="Agregar recibo" size="lg" ok-title="Guardar" cancel-title="Cancelar" @ok="validar" @cancel="cancelar_recibos">
                                    <div class="row">
                                        <div class="offset-md-7 col-md-5">
                                            <b-form-group label-cols="4" label-cols-lg="2" label="Fecha:" label-for="input-default">
                                                <b-form-input type="date" id="input-default" v-model="datos.fecha"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <b-form-group label-cols="4" label-cols-lg="2" label="Rut:" label-for="input-default">
                                                <b-form-input id="input-default" v-model="datos.rut"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-8">
                                            <b-form-group label-cols="4" label-cols-lg="2" label="Recibí de:" label-for="input-default">
                                                <b-form-input id="input-default" v-model="datos.recibide"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12 text-left">
                                            <b-form-group label-cols="4" label-cols-lg="2" label="Asignación:" label-for="input-default">
                                                <select v-model="datos.asignacion" class="form-control">
                                                    <option disabled value="">Seleccione una opción</option>
                                                    <option value="no especifico">No específico</option>
                                                    <option value="local">Local</option>
                                                    <option value="sala venta">Sala Venta</option>
                                                    <option value="web">Web</option>
                                                    <option value="terreno">Terreno</option>
                                                </select>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12 text-left">
                                            <label class="">Por Concepto de:</label>
                                            <b-button variant="primary" class="float-right" size="sm" @click="factura()">Agregar concepto</b-button>
                                            <div class="row text-center mt-3" v-if="datos.facturas.length>0">
                                                <div class="col-md-5 p-1">
                                                    <label>Nº Factura</label>
                                                </div>
                                                <div class="col-md-3 p-1">
                                                    <label for="">Monto</label>
                                                </div>
                                                <div class="col-md-3 p-1">
                                                    <label for="">Fecha</label>
                                                </div>
                                                <div class="col-md-1">
                                                </div>
                                            </div>
                                            <div v-for="(factura, index) in datos.facturas" :key="index">
                                                <div class="row">
                                                    <div class="col-md-5 p-1">
                                                        <b-form-input id="input-default" v-model="factura.concepto"></b-form-input>
                                                    </div>
                                                    <div class="col-md-3 p-1">
                                                        <b-form-input id="input-default" v-model="factura.valor"></b-form-input>
                                                    </div>
                                                    <div class="col-md-3 p-1">
                                                        <b-form-input type="date" id="input-default" v-model="factura.fecha"></b-form-input>
                                                    </div>
                                                    <div class="col-md-1">
                                                        <b-button variant="danger" @click="eliminar(index)"><i class="bx bx-trash"></i></b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <b-form-group class="text-left" label-cols="4" label-cols-lg="2" label="Total :" label-for="input-default">
                                        <p class="p-2">{{ sumaTotal }}</p>
                                    </b-form-group>
                                    <b-form-group label-for="input-default">
                                        <b-form-checkbox-group id="checkbox-group-2" v-model="datos.pago" name="flavour-6">
                                            <b-form-checkbox value="efectivo">Efectivo</b-form-checkbox>
                                            <b-form-checkbox value="cheque">Cheque</b-form-checkbox>
                                            <b-form-checkbox value="tarjeta">Tarjeta</b-form-checkbox>
                                            <b-form-checkbox value="webpay">Webpay</b-form-checkbox>
                                            <b-form-checkbox value="transferencia">Transferencia</b-form-checkbox>
                                        </b-form-checkbox-group>
                                    </b-form-group>
                                    <div class="row">
                                        <div class="col-md-12" v-if="datos.pago.includes('cheque')">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <b-form-group label-cols="4" label-cols-lg="2" label="Nº :" label-for="input-default">
                                                        <b-form-input id="input-default" v-model="datos.mediopago.n"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-4">
                                                    <b-form-group label-cols="4" label-cols-lg="3" label="Banco :" label-for="input-default">
                                                        <b-form-input id="input-default" v-model="datos.mediopago.banco"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-4">
                                                    <b-form-group label-cols="4" label-cols-lg="3" label="Fecha :" label-for="input-default">
                                                        <b-form-input type="date" id="input-default" v-model="datos.mediopago.fecha"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12" v-if="datos.pago.includes('tarjeta') ">
                                            <b-form-radio-group id="checkbox-group-2" v-model="datos.mediopago.tipotarjeta" name="flavour-1">
                                                <b-form-radio value="credito">Crédito</b-form-radio>
                                                <b-form-radio value="debito">Débito</b-form-radio>
                                            </b-form-radio-group>
                                        </div>
                                        <div class="col-md-12" v-if=" datos.pago.includes('webpay')">
                                            <b-form-radio-group id="checkbox-group-2" v-model="datos.mediopago.tipowebpay" name="flavour-1">
                                                <b-form-radio value="credito">Crédito</b-form-radio>
                                                <b-form-radio value="debito">Débito</b-form-radio>
                                            </b-form-radio-group>
                                        </div>
                                    </div>
                                </b-modal>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;recibos
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                class="table-nowrap"
                                :items="recibos"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(n)="data">
                                    <p>{{ data.index+1 }}</p>
                                </template>
                                <template v-slot:cell(tipopago)="data">
                                    <p class="p-0 m-0" v-for="(pago,index) in data.item.pago " :key="index">{{ pago }}</p>
                                </template>
                                <template v-slot:cell(accion)="data">
                                    <b-button variant="primary" @click="editar_modal(data.item)" size="sm" :disabled="data.item.ncontable==null?false :true">Editar</b-button>
                                    <b-button :variant="data.item.ncontable==null?'danger' :'success'" @click="contabilidad_modal(data.item)" size="sm">Contabilidad</b-button>
                                    <b-button variant="danger" @click="modal_observacion(data.item)" size="sm" :disabled="data.item.ncontable==null?false :true"><i class="bx bx-trash"></i></b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <b-modal ref="modal-observacion" no-close-on-backdrop title="Observación" ok-title="Guardar" cancel-title="Cancelar" @ok="eliminar_recibo">
                            <b-form-group label-for="input-default">
                                <b-form-textarea
                                    id="textarea"
                                    v-model="observacion.observacion"
                                    rows="3"
                                    max-rows="6"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-modal>
                        <b-modal ref="modal-recibo-editar" no-close-on-backdrop title="Editar recibo" size="lg" ok-title="Editar" cancel-title="Cancelar" @ok="editar_recibos">
                            <div class="row">
                                <div class="offset-md-6 col-md-6">
                                    <b-form-group label-cols="4" label-cols-lg="2" label="Estado:" label-for="input-default">
                                        <select v-model="datos_editar.estado" class="form-control">
                                            <option disabled value="">Seleccione un estado</option>
                                            <option value="vigente">Vigente</option>
                                            <option value="nulo">Nulo</option>
                                        </select>
                                    </b-form-group>
                                    <b-form-group label-cols="4" label-cols-lg="2" label="Fecha:" label-for="input-default">
                                        <b-form-input type="date" id="input-default" v-model="datos_editar.fecha"></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-4">
                                    <b-form-group label-cols="4" label-cols-lg="2" label="Rut:" label-for="input-default">
                                        <b-form-input id="input-default" v-model="datos_editar.rut"></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-8">
                                    <b-form-group label-cols="4" label-cols-lg="2" label="Recibí de:" label-for="input-default">
                                        <b-form-input id="input-default" v-model="datos_editar.recibide"></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-12 text-left">
                                    <b-form-group label-cols="4" label-cols-lg="2" label="Asignación:" label-for="input-default">
                                        <select v-model="datos_editar.asignacion" class="form-control">
                                            <option disabled value="">Seleccione una opción</option>
                                            <option value="no especifico">No específico</option>
                                            <option value="local">Local</option>
                                            <option value="sala venta">Sala Venta</option>
                                            <option value="web">Web</option>
                                            <option value="terreno">Terreno</option>
                                        </select>
                                    </b-form-group>
                                </div>
                                <div class="col-md-12 text-left">
                                    <label class="">Por Concepto de:</label>
                                    <b-button variant="primary" class="float-right" size="sm" @click="factura_editar()">Agregar concepto</b-button>
                                    <div class="row text-center mt-3" v-if="datos.facturas.length>0">
                                        <div class="col-md-5 p-1">
                                            <label>Nº Factura</label>
                                        </div>
                                        <div class="col-md-3 p-1">
                                            <label for="">Monto</label>
                                        </div>
                                        <div class="col-md-3 p-1">
                                            <label for="">Fecha</label>
                                        </div>
                                        <div class="col-md-1">
                                        </div>
                                    </div>
                                    <div v-for="(factura, index) in datos_editar.facturas" :key="index">
                                        <div class="row">
                                            <div class="col-md-5 p-1">
                                                <b-form-input id="input-default" v-model="factura.concepto"></b-form-input>
                                            </div>
                                            <div class="col-md-3 p-1">
                                                <b-form-input id="input-default" v-model="factura.valor"></b-form-input>
                                            </div>
                                            <div class="col-md-3 p-1">
                                                <b-form-input type="date" id="input-default" v-model="factura.fecha"></b-form-input>
                                            </div>
                                            <div class="col-md-1">
                                                <b-button variant="danger" @click="eliminar_editar(index)"><i class="bx bx-trash"></i></b-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <b-form-group class="text-left" label-cols="4" label-cols-lg="2" label="Total :" label-for="input-default">
                                <p class="p-2">{{ sumaTotal_editar }}</p>
                            </b-form-group>
                            <b-form-group label-for="input-default">
                                <b-form-checkbox-group id="checkbox-group-2" v-model="datos_editar.pago" name="flavour-6">
                                    <b-form-checkbox value="efectivo">Efectivo</b-form-checkbox>
                                    <b-form-checkbox value="cheque">Cheque</b-form-checkbox>
                                    <b-form-checkbox value="tarjeta">Tarjeta</b-form-checkbox>
                                    <b-form-checkbox value="webpay">Webpay</b-form-checkbox>
                                    <b-form-checkbox value="transferencia">Transferencia</b-form-checkbox>
                                </b-form-checkbox-group>
                            </b-form-group>
                            <div class="row">
                                <div class="col-md-12" v-if="datos.pago.includes('cheque')">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <b-form-group label-cols="4" label-cols-lg="2" label="Nº :" label-for="input-default">
                                                <b-form-input id="input-default" v-model="datos_editar.mediopago.n"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <b-form-group label-cols="4" label-cols-lg="3" label="Banco :" label-for="input-default">
                                                <b-form-input id="input-default" v-model="datos_editar.mediopago.banco"></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <b-form-group label-cols="4" label-cols-lg="3" label="Fecha :" label-for="input-default">
                                                <b-form-input type="date" id="input-default" v-model="datos.mediopago.fecha"></b-form-input>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" v-if="datos_editar.pago.includes('tarjeta') ">
                                    <b-form-radio-group id="checkbox-group-2" v-model="datos_editar.mediopago.tipotarjeta" name="flavour-1">
                                        <b-form-radio value="credito">Crédito</b-form-radio>
                                        <b-form-radio value="debito">Débito</b-form-radio>
                                    </b-form-radio-group>
                                </div>
                                <div class="col-md-12" v-if=" datos_editar.pago.includes('webpay')">
                                    <b-form-radio-group id="checkbox-group-2" v-model="datos_editar.mediopago.tipowebpay" name="flavour-1">
                                        <b-form-radio value="credito">Crédito</b-form-radio>
                                        <b-form-radio value="debito">Débito</b-form-radio>
                                    </b-form-radio-group>
                                </div>
                            </div>
                        </b-modal>
                        <b-modal ref="modal-contabilidad" title="Datos contabilidad" size="lg" ok-title="Guardar" cancel-title="Cancelar" @ok="validacion_contabilidad">
                            <div class="row">
                                <div class="col-md-6">
                                    <b-form-group label-cols="4" label-cols-lg="4" label="Nº contable:" label-for="input-default">
                                        <b-form-input type="text" id="input-default" v-model="contabilidad.ncontable" :disabled="contabilidad.booln"></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group label-cols="4" label-cols-lg="4" label="Fecha contable:" label-for="input-default">
                                        <b-form-input type="date" id="input-default" v-model="contabilidad.fechacontable" :disabled="contabilidad.boolfecha"></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-12 text-left" v-if="contabilidad.asignacion=='sala venta'">
                                    <b-form-checkbox :value="true" :unchecked-value="false" v-model="contabilidad.selectAll"  @change="toggleAll">Seleccionar todo</b-form-checkbox>
                                    <div class="table-responsive">
                                        <table class="table table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Rut</th>
                                                    <th>Recibí de</th>
                                                    <th>Total</th>
                                                    <th>Tipo de pago</th>
                                                    <th>Fecha</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(recibo,index) in contabilidad.mismos" :key="index">
                                                    <th scope="row"> <b-form-checkbox :value="recibo.id" v-model="contabilidad.arreglo" @change="checkIfAllSelected" :disabled="recibo.id==contabilidad.id?true:false"></b-form-checkbox></th>
                                                    <td>{{ recibo.rut }}</td>
                                                    <td>{{ recibo.recibide }}</td>
                                                    <td>{{ recibo.suma }}</td>
                                                    <td>{{ recibo.pago }}</td>
                                                    <td>{{ fecha(recibo.fecha) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <h4>Total: {{ suma }}</h4>
                                    <!-- <b-form-checkbox-group id="checkbox-group-10" v-model="contabilidad.arreglo" name="flavour-10">
                                        <b-form-checkbox :value="recibo.id" v-for="(recibo,index) in contabilidad.mismos" :key="index">{{ recibo }}</b-form-checkbox>
                                    </b-form-checkbox-group> -->
                                </div>
                            </div>
                        </b-modal>
                        <div class="row mt-4">
                            <div class="col-md-12 text-center">
                                <b-form-radio-group
                                    id="btn-radios-2"
                                    v-model="selected"
                                    :options="options"
                                    button-variant="outline-primary"
                                    size="lg"
                                    name="radio-btn-outline"
                                    buttons
                                    @change="filtro_tabla"
                                ></b-form-radio-group>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage2" size="sm" :options="pageOptions2"></b-form-select>&nbsp;recibos
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter2"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                class="table-nowrap"
                                :items="recibos2"
                                :fields="fields2"
                                responsive="sm"
                                :per-page="perPage2"
                                :current-page="currentPage2"
                                :sort-by.sync="sortBy2"
                                :sort-desc.sync="sortDesc2"
                                :filter="filter2"
                                :filter-included-fields="filterOn2"
                                @filtered="onFiltered2"
                            >
                                <template v-slot:cell(n)="data">
                                    <p>{{ data.index+1 }}</p>
                                </template>
                                <template v-slot:cell(tipopago)="data">
                                    <p>{{ data.item.pago }}</p>
                                </template>
                                <template v-slot:cell(accion)="data">
                                    <b-button variant="primary" @click="ver_modal(data.item)" size="sm"><i class="bx bx-show font-size-14"></i></b-button>
                                    <b-button variant="danger" @click="modal_obs(data.item)" size="sm" v-if="data.item.estado!='eliminado'">Anular</b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage2" :total-rows="rows2" :per-page="perPage2"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <b-modal ref="modal-ver" no-close-on-backdrop title="Recibo" size="lg" ok-title="Aceptar" ok-only>
                            <div class="row text-left p-4 bg-primary">
                                <div class="col-md-6">
                                    <p class="text-white"><b>Estado:</b> {{ capitalizarPrimeraLetra(info.estado) }}</p>
                                    <p class="text-white"><b>Fecha:</b> {{ fecha(info.fecha) }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="text-white"><b>Nº Contable:</b> {{ info.ncontable }}</p>
                                    <p class="text-white"><b>Fecha Contable:</b> {{ fecha(info.fechacontable) }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="text-white"><b>Rut:</b> {{ info.rut }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="text-white"><b>Recibí de:</b> {{ capitalizarPrimeraLetra(info.recibide) }}</p>
                                </div>
                                <div class="col-md-12">
                                    <p class="text-white"><b>Asignación:</b> {{ capitalizarPrimeraLetra(info.asignacion) }}</p>
                                </div>
                            </div>
                            <div class="row text-left p-3">
                                <div class="col-md-12">
                                    <p class="text-muted"><b>Por Concepto de: </b></p>
                                    <div class="table-responsive">
                                        <table class="table table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Nº Factura</th>
                                                    <th>Monto</th>
                                                    <th>Fecha</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(factura, index) in info.facturas" :key="index">
                                                    <th scope="row">{{ factura.concepto }}</th>
                                                    <td>{{ factura.valor }}</td>
                                                    <td>{{ factura.fecha }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <p class="text-muted"><b>Total:</b> {{ info.suma }}</p>
                                </div>
                            </div>
                            <div class="row text-left p-3 bg-primary">
                                <div class="col-md-12">
                                    <p class="text-white"><b>Medio de pago:</b> {{ capitalizarPrimeraLetra(info.pago) }}</p>
                                    <p class="text-white" v-if="info.pago=='cheque'"><b>Nº:</b> {{ (info.mediopago.n) }}</p>
                                    <p class="text-white" v-if="info.pago=='cheque'"><b>Banco:</b> {{ capitalizarPrimeraLetra(info.mediopago.banco) }}</p>
                                    <p class="text-white" v-if="info.pago=='cheque'"><b>Fecha:</b> {{ fecha(info.mediopago.fecha) }}</p>
                                    <p class="text-white" v-if="info.pago=='tarjeta' && info.mediopago.tarjeta=='transferencia'"> {{ capitalizarPrimeraLetra(info.mediopago.tarjeta) }}</p>
                                    <p class="text-white" v-if="info.pago=='tarjeta' && info.mediopago.tarjeta=='webpay'"> {{ capitalizarPrimeraLetra(info.mediopago.tarjeta) +' - ' + capitalizarPrimeraLetra(info.mediopago.tipo) }}</p>
                                </div>
                            </div>
                        </b-modal>
                        <b-modal ref="modal-obs" no-close-on-backdrop title="Observación" ok-title="Guardar" cancel-title="Cancelar" @ok="obser">
                            <b-form-group label-for="input-default">
                                <b-form-textarea
                                    id="textarea"
                                    v-model="obs.observacion"
                                    rows="3"
                                    max-rows="6"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>