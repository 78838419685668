<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import DatePicker from "vue2-datepicker";
import moment from "moment";
/**
 * Advanced table component
 */
export default {
    components: { Layout, PageHeader, DatePicker },
    data() {
        return {
            comisiones:[],
            title: "Advanced Table",
            items: [
                {
                text: "Tables",
                href: "/"
                },
                {
                text: "Advanced",
                active: true
                }
            ],
            desde:'',
            hasta:'',
            vendedor:'',
            facturas:false,
            info:{
                estado:'vigente',
                fecha:'2024-10-01',
                ncontable:'5446531',
                fechacontable:'2024-10-12',
                rut:'12345678-9',
                recibide:'dsfghjkljmhnb',
                asignacion:'sala venta',
                asignacion:'sala venta',
                facturas:{},
                suma:0,
                pago:'efectivo',
                medio:{},
            }
        };
    },
    methods: {
        fecha(fechahora){
            var orden = moment(fechahora).format('DD-MM-YYYY');
            return orden
        },
        capitalizarPrimeraLetra(text) {
            if (!text) return '';
            return text.charAt(0).toUpperCase() + text.slice(1);
        },
        mostrarresumen(){
            if (this.facturas) {
                this.facturas=false;
            }else{
                this.facturas=true;
            }
        },
        mostrarrecibo(item){
            this.info=item;
            this.$refs['modal-ver'].show()
        }
    },
    mounted(){
        const fecha = new Date();
        const dia = fecha.getDate();
        const mes = fecha.getMonth() + 1; // Se suma 1 porque getMonth() devuelve un índice de 0 (enero) a 11 (diciembre)
        const año = fecha.getFullYear();
        this.desde = año+'-'+mes+'-'+dia;
        this.hasta = año+'-'+(mes+1)+'-30';
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <h4 class="card-title">Comisiones</h4>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card bg-primary">
                            <div class="card-body">
                                <div class="row mt-4">
                                    <div class="col-md-5 pt-1">
                                        <b-form-group id="example text" class="text-white" label-cols-sm="2" label-cols-lg="3" label="Desde :" label-for="text" >
                                            <date-picker v-model="desde" value-type="YYYY-MM-DD" format="DD-MM-YYYY" :first-day-of-week="2" lang="es" ></date-picker>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-5 pt-1">
                                        <b-form-group id="example text" class="text-white" label-cols-sm="2" label-cols-lg="3" label="Hasta :" label-for="text" >
                                            <date-picker v-model="hasta" value-type="YYYY-MM-DD" format="DD-MM-YYYY" :first-day-of-week="2" lang="es" ></date-picker>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-2">
                                        <b-button variant="light" block @click="mostrarresumen()">Ver</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="offset-md-3 col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Vendedor</th>
                                                <th>Total</th>
                                                <th>Total Neto</th>
                                                <th>Comisión</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">Pamela</th>
                                                <td>803920</td>
                                                <td>675560</td>
                                                <td>{{Math.round(675560*0.03)}}</td>
                                                <td><b-button variant="primary" size="sm" @click=mostrarresumen>Detalles</b-button></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Álvaro</th>
                                                <td>698265</td>
                                                <td>527893</td>
                                                <td>{{Math.round(527893*0.03)}}</td>
                                                <td><b-button variant="primary" size="sm" @click=mostrarresumen>Detalles</b-button></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Sandra</th>
                                                <td>803319</td>
                                                <td>675455</td>
                                                <td>{{Math.round(675455*0.05)}}</td>
                                                <td><b-button variant="primary" size="sm" @click=mostrarresumen>Detalles</b-button></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Cristóbal</th>
                                                <td>803925</td>
                                                <td>675565</td>
                                                <td>{{Math.round(675565*0.03)}}</td>
                                                <td><b-button variant="primary" size="sm" @click=mostrarresumen>Detalles</b-button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" v-if="facturas">
                        <div class="card">
                            <div class="card-body">
                                <div>
                                    <b-button variant="primary" class="float-right">Exportar</b-button>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Rut</th>
                                                <th>Razón social</th>
                                                <th>N CI</th>
                                                <th>Total</th>
                                                <th>Neto</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">76713279</th>
                                                <td>Servicios Dentales</td>
                                                <td>28662</td>
                                                <td>80392</td>
                                                <td>67556</td>
                                                <td><b-button variant="primary" size="sm" @click=mostrarrecibo><i class="bx bx-show"></i> </b-button></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">76757328</th>
                                                <td>Servicios Odont. Medina</td>
                                                <td>28663</td>
                                                <td>50000</td>
                                                <td>42017</td>
                                                <td><b-button variant="primary" size="sm" @click=mostrarrecibo><i class="bx bx-show"></i> </b-button></td>
                                            </tr>
                                            <tr>
                                                <th scope="row">76918411</th>
                                                <td>Servicios Odont. Katherine</td>
                                                <td>28664</td>
                                                <td>335937</td>
                                                <td>282300</td>
                                                <td><b-button variant="primary" size="sm" @click=mostrarrecibo><i class="bx bx-show"></i> </b-button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <b-modal ref="modal-ver" no-close-on-backdrop title="Recibo" size="lg" ok-title="Aceptar" ok-only>
                                    <div class="row text-left p-4 bg-primary">
                                        <div class="col-md-6">
                                            <p class="text-white"><b>Estado:</b> {{ capitalizarPrimeraLetra(info.estado) }}</p>
                                            <p class="text-white"><b>Fecha:</b> {{ fecha(info.fecha) }}</p>
                                        </div>
                                        <div class="col-md-6">
                                            <p class="text-white"><b>Nº Contable:</b> {{ info.ncontable }}</p>
                                            <p class="text-white"><b>Fecha Contable:</b> {{ fecha(info.fechacontable) }}</p>
                                        </div>
                                        <div class="col-md-6">
                                            <p class="text-white"><b>Rut:</b> {{ info.rut }}</p>
                                        </div>
                                        <div class="col-md-6">
                                            <p class="text-white"><b>Recibí de:</b> {{ capitalizarPrimeraLetra(info.recibide) }}</p>
                                        </div>
                                        <div class="col-md-12">
                                            <p class="text-white"><b>Asignación:</b> {{ capitalizarPrimeraLetra(info.asignacion) }}</p>
                                        </div>
                                    </div>
                                    <div class="row text-left p-3">
                                        <div class="col-md-12">
                                            <p class="text-muted"><b>Por Concepto de: </b></p>
                                            <div class="table-responsive">
                                                <table class="table table-hover mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Nº Factura</th>
                                                            <th>Monto</th>
                                                            <th>Fecha</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(factura, index) in info.facturas" :key="index">
                                                            <th scope="row">{{ factura.concepto }}</th>
                                                            <td>{{ factura.valor }}</td>
                                                            <td>{{ factura.fecha }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p class="text-muted"><b>Total:</b> {{ info.suma }}</p>
                                        </div>
                                    </div>
                                    <div class="row text-left p-3 bg-primary">
                                        <div class="col-md-12">
                                            <p class="text-white"><b>Medio de pago:</b> {{ capitalizarPrimeraLetra(info.pago) }}</p>
                                            <p class="text-white" v-if="info.pago=='cheque'"><b>Nº:</b> {{ (info.mediopago.n) }}</p>
                                            <p class="text-white" v-if="info.pago=='cheque'"><b>Banco:</b> {{ capitalizarPrimeraLetra(info.mediopago.banco) }}</p>
                                            <p class="text-white" v-if="info.pago=='cheque'"><b>Fecha:</b> {{ fecha(info.mediopago.fecha) }}</p>
                                            <p class="text-white" v-if="info.pago=='tarjeta' && info.mediopago.tarjeta=='transferencia'"> {{ capitalizarPrimeraLetra(info.mediopago.tarjeta) }}</p>
                                            <p class="text-white" v-if="info.pago=='tarjeta' && info.mediopago.tarjeta=='webpay'"> {{ capitalizarPrimeraLetra(info.mediopago.tarjeta) +' - ' + capitalizarPrimeraLetra(info.mediopago.tipo) }}</p>
                                        </div>
                                    </div>
                                </b-modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>