<script>

/**
 * Starter component
 */
export default {
    props:{
        titulo:'',
        productos:[],
    },
    data() {
        return {
            windowWidth3: window.innerWidth,
            windowHeight3: window.innerHeight
        };
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods:{
        handleResize() {
            this.windowWidth3 = window.innerWidth;
            this.windowHeight3 = window.innerHeight;
        },
    }
};
</script>
<template>
    <div>
        <h3 class="pl-3 pt-4">{{ titulo }}</h3>
        <div class="product-container">
            <div :class="windowWidth3>576 ? 'product-card' : 'product-sm-card'" v-for="(producto,index) in productos" :key="index">
                <div class="border pt-3 pb-3 text-center position-relative" >
                    <div v-if="producto.promocion" class="promotion-label">Promoción</div>
                    <img :src="'/images/productos_web/producto-sin-imagen.png'" alt :class="windowWidth3>576 ?'rounded avatar-3xl': 'rounded avatar-lg'" v-if="producto.foto_dir=='' || producto.foto_dir==null"/>
                    <img :src="producto.foto_dir" alt :class="windowWidth3>576 ?'rounded avatar-3xl': 'rounded avatar-lg'" v-else/>
                </div>
                <p class="pt-2 m-0 text-danger font-size-12" v-if="producto.precio_rebajado!=null && producto.precio_rebajado!=0">Oferta - {{producto.porcentaje}}% OFF</p>
                <p class="pt-2 m-0 text-danger font-size-12" v-else><br></p>
                <!-- <p class="p-1 m-0 text-secondary font-size-10" v-for="cate in producto.cat" :key="cate.id">{{cate.nombre}}</p> -->
                <h2 class="product-title">{{producto.nombre}}</h2>
                <span v-if="producto.tipo=='Simple' && producto.estado_inventario=='hay'">
                    <p class="product-price" v-if="producto.precio_rebajado!=null"><del class="text-secondary">${{ producto.precio_normal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</del> - ${{producto.precio_rebajado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</p>
                    <p class="product-price" v-else-if="producto.precio_normal || producto.precio_normal!=''">$ {{producto.precio_normal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</p>
                </span>
                <span v-else-if="producto.tipo=='Variable'">
                    <p class="product-price" v-if="producto.precio_rebajado!=0">Desde $ {{producto.precio_rebajado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</p>
                    <p class="product-price" v-else-if="producto.precio_normal|| producto.precio_normal!=''">Desde $ {{producto.precio_normal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</p>
                </span>
                <div class="m-0 p-0">
                    <p class="float-right text-primary font-size-12 m-0 p-0"><b>{{producto.nombre_marca}}</b></p>
                </div>
                <div class="mt-auto pt-4 p-0 m-0 card-footer" style="background-color: transparent;">
                    <b-button variant="primary" block pill :href="'/producto/'+producto.id">Ver producto</b-button>
                </div>
            </div>
        </div>                    
    </div>
</template>
<style>
.product-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 250px;
    margin: 20px;
    display: flex;
    flex-direction: column;
}
.product-sm-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
   
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    width: 150px;
    margin: 10px;
    display: flex;
    flex-direction: column;
}

.product-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

.product-price {
    font-size: 14px;
    font-weight: bold;
    color: #556ee6;
    margin-top: auto;
}
</style>