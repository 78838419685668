var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"sidebar-menu"}},[_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[_c('li',{staticClass:"menu-title"},[_vm._v("Menú")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_vm._m(6),_vm._v(" "),(false)?_c('li',[_vm._m(7)]):_vm._e(),_vm._v(" "),(false)?_c('li',[_vm._m(8)]):_vm._e(),_vm._v(" "),_vm._m(9)]),_vm._v(" "),_c('ul',{staticClass:"metismenu list-unstyled",attrs:{"id":"side-menu"}},[_c('li',{staticClass:"menu-title"},[_vm._v("Menú Web")]),_vm._v(" "),_vm._m(10),_vm._v(" "),_vm._m(11),_vm._v(" "),_vm._m(12),_vm._v(" "),_vm._m(13),_vm._v(" "),_vm._m(14),_vm._v(" "),_vm._m(15),_vm._v(" "),_vm._m(16),_vm._v(" "),_vm._m(17),_vm._v(" "),(false)?_c('li',[_vm._m(18)]):_vm._e(),_vm._v(" "),(_vm.user.rol=='Admin')?_c('li',[_vm._m(19)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/inicio"}},[_c('i',{staticClass:"bx bx-home-circle"}),_vm._v(" "),_c('span',[_vm._v("Inicio")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/productos"}},[_c('i',{staticClass:"bx bxs-purchase-tag-alt"}),_vm._v(" "),_c('span',[_vm._v("Productos")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/licitaciones"}},[_c('i',{staticClass:"bx bxs-book-content"}),_vm._v(" "),_c('span',[_vm._v("Licitaciones")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/clientes"}},[_c('i',{staticClass:"bx bx bxs-group"}),_vm._v(" "),_c('span',[_vm._v("Clientes")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/marcas"}},[_c('i',{staticClass:"bx bxs-purchase-tag"}),_vm._v(" "),_c('span',[_vm._v("Marcas")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/despacho"}},[_c('i',{staticClass:"bx bxs-package"}),_vm._v(" "),_c('span',[_vm._v("Despacho")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/analisis"}},[_c('i',{staticClass:"bx bx-line-chart"}),_vm._v(" "),_c('span',[_vm._v("Análisis")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/recibos"}},[_c('i',{staticClass:"bx bxs-purchase-tag-alt"}),_vm._v(" "),_c('span',[_vm._v("Recibos electrónicos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/comisiones"}},[_c('i',{staticClass:"bx bxs-purchase-tag-alt"}),_vm._v(" "),_c('span',[_vm._v("Comisiones")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/mercado_publico"}},[_c('i',{staticClass:"bx bx-line-chart"}),_vm._v(" "),_c('span',[_vm._v("Mercado Público")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/"}},[_c('i',{staticClass:"bx bx-home-circle"}),_vm._v(" "),_c('span',[_vm._v("Ver Web")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/cargar-productos"}},[_c('i',{staticClass:"bx bxs-purchase-tag-alt"}),_vm._v(" "),_c('span',[_vm._v("Cargar Productos")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/descuentos"}},[_c('i',{staticClass:"bx bxs-purchase-tag-alt"}),_vm._v(" "),_c('span',[_vm._v("Agregar Descuentos")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/descargar-productos"}},[_c('i',{staticClass:"bx bxs-purchase-tag-alt"}),_vm._v(" "),_c('span',[_vm._v("Descarga Masiva")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/categorias"}},[_c('i',{staticClass:"bx bxs-book-content"}),_vm._v(" "),_c('span',[_vm._v("Categorías")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/slides"}},[_c('i',{staticClass:"bx bx bxs-group"}),_vm._v(" "),_c('span',[_vm._v("Slides")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/ordenescompra"}},[_c('i',{staticClass:"bx bxs-purchase-tag-alt"}),_vm._v(" "),_c('span',[_vm._v("Ordenes de Compra")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/promociones"}},[_c('i',{staticClass:"bx bxs-purchase-tag-alt"}),_vm._v(" "),_c('span',[_vm._v("Promociones")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/recibos"}},[_c('i',{staticClass:"bx bxs-purchase-tag-alt"}),_vm._v(" "),_c('span',[_vm._v("Recibos electrónicos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"side-nav-link-ref",attrs:{"href":"/csvs"}},[_c('i',{staticClass:"bx bx-line-chart"}),_vm._v(" "),_c('span',[_vm._v("CSV")])])
}]

export { render, staticRenderFns }