<script>
/**
 * Login component
 */
export default {
  data() {
    return {
      email: "",
      password: "",
      tryingToLogIn: false,
      isAuthError: false,
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    };
  },
  props: {
    submiturl: {
      type: String,
      required: true
    },
    autherror: {
      type: String,
      required: false,
      default: () => null
    }
  },
  mounted() {
      this.isAuthError = !!this.authError;
  }
};
</script>

<template>
  <div class="row justify-content-center">
    <div class="col-md-8 col-lg-6 col-xl-5">
      <img src="/archivos_base/logo.png" alt class="img-fluid pb-3" />
      <div class="card overflow-hidden">
        <div class="bg-soft-primary">
          <div class="row">
            <div class="col-12">
              <div class="text-primary p-4">
                <h5 class="text-primary">¡Bienvenido, inicia sesión para continuar!</h5>
                <!-- <p>Sign in to continue to Skote.</p> -->
              </div>
            </div>
            <!-- <div class="col-5 align-self-end">
              <img src="/images/profile-img.png" alt class="img-fluid" />
            </div> -->
          </div>
        </div>
        <div class="card-body pt-2">
          <!-- <div>
            <a href="/">
              <div class="avatar-md profile-user-wid mb-4">
                <span class="avatar-title rounded-circle bg-light">
                  <img src="/images/logo.svg" alt height="34" />
                </span>
              </div>
            </a>
          </div> -->

          <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{autherror}}</b-alert>

          <b-form class="p-2" :action="submiturl" method="POST">
            <input type="hidden" name="_token" :value="csrf">
            <slot />
            <b-form-group id="input-group-1" label="Correo Electrónico" label-for="input-1">
              <b-form-input id="input-1" name="email" v-model="email" type="text" placeholder="Ingresar Correo"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="Contraseña" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="password" name="password"
                type="password"
                placeholder="Ingresar Contraseña"
              ></b-form-input>
            </b-form-group>
            <!-- <div class="custom-control custom-checkbox">
              <input id="customControlInline" type="checkbox" class="custom-control-input" />
              <label class="custom-control-label" for="customControlInline">Recuerdame</label>
            </div> -->
            <div class="mt-3">
              <b-button type="submit" variant="primary" class="btn-block">Ingresar</b-button>
            </div>
            <div class="mt-4 text-center">
              <a href="/password/reset" class="text-muted">
                <i class="mdi mdi-lock mr-1"></i> ¿Olvidaste tu contraseña?
              </a>
            </div>
          </b-form>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->

      <div class="mt-5 text-center">
        <!-- <p>
          ¿No tienes cuenta?
          <a
            href="/register"
            class="font-weight-medium text-primary"
          >Registrate ahora</a>
        </p> -->
        <p>
          © {{new Date().getFullYear()}} Vamproden
        </p>
      </div>
      <!-- end row -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>

