var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer2"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 mb-3"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"d-sm-block d-lg-none text-center"},[_c('a',{staticClass:"logo logo-dark text-center",attrs:{"href":"https://www.vamproden.cl/"}},[_c('span',{staticClass:"logo-sm"},[_c('img',{attrs:{"src":"/archivos_base/logoblanco.png","alt":"","height":_vm.windowWidth5>1024 ? '70':'50'}})])])])]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('div',{staticClass:"col-md-3 mb-3 text-center"},[_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_c('b-button',{staticClass:"m-0 p-0",attrs:{"variant":"none","href":"/info/terminoscondiciones","target":"_blank"}},[_c('p',{staticClass:"text-white font-size-16"},[_c('i',{staticClass:"text-white bx bx-receipt"}),_vm._v(" Términos y Condiciones")])])],1),_vm._v(" "),_c('div',{staticClass:"col-md-3 mb-3"},[_vm._m(5),_vm._v(" "),_c('div',{class:_vm.windowWidth5<1024 ? 'text-center':''},[_vm._m(6)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pl-4 mt-5"},[_c('a',{staticClass:"logo logo-dark",attrs:{"href":"https://www.vamproden.cl/"}},[_c('span',{staticClass:"logo-lg"},[_c('img',{attrs:{"src":"/archivos_base/logoblanco.png","alt":"","height":"70"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 text-center"},[_c('p',{staticClass:"text-white font-size-16"},[_c('i',{staticClass:"text-white bx bx-time-five"}),_vm._v(" Horario de atención:")]),_vm._v(" "),_c('p',{staticClass:"text-white font-size-16 pb-0 mb-0"},[_vm._v("Lunes - Jueves")]),_vm._v(" "),_c('p',{staticClass:"text-white font-size-16 pt-0 mt-0 pb-0 mb-0"},[_vm._v("9:00 - 18:30")]),_vm._v(" "),_c('p',{staticClass:"text-white font-size-16 pt-0 mt-0 pb-0 mb-0"},[_vm._v("Viernes")]),_vm._v(" "),_c('p',{staticClass:"text-white font-size-16 pt-0 mt-0"},[_vm._v("09:00 - 17:30")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-white font-size-16"},[_c('i',{staticClass:"text-white bx bxs-compass"}),_vm._v(" Errázuriz 2066, Valdivia")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-white font-size-16"},[_c('i',{staticClass:"text-white bx bx-mobile"}),_vm._v(" 63 2 219881 - 63 2 204385")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-white font-size-16"},[_c('i',{staticClass:"text-white bx bxs-envelope"}),_vm._v(" contacto@vamproden.cl")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"logos"}},[_c('a',{staticClass:"logo_footer",attrs:{"href":"https://www.facebook.com/profile.php?id=100063517005609","target":"_blank"}},[_c('i',{staticClass:"bx bxl-facebook-square font-size-40"})]),_vm._v(" "),_c('a',{staticClass:"logo_footer",attrs:{"href":"https://www.instagram.com/vamproden.chile/","target":"_blank"}},[_c('i',{staticClass:"bx bxl-instagram font-size-40"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.webpay.cl/company/27935","target":"_blank"}},[_c('img',{attrs:{"src":"/archivos_base/webpay_blanco.webp","width":"200vw","height":"auto"}})])
}]

export { render, staticRenderFns }