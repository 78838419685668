<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import moment from "moment";
/**
 * Advanced table component
 */
export default {
    components: { Layout, PageHeader },
    props:{
        regiones:[],
        comunas:[],
    },
    data() {
        return {
            title: "Advanced Table",
            items: [
                {
                text: "Tables",
                href: "/"
                },
                {
                text: "Advanced",
                active: true
                }
            ],
            ordenes:[],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "id",
            sortDesc: true,
            fields: [
                { key: "id",label:"Folio", sortable: true, thStyle: { width: '60px' } },
                { key: "status",label:"Estado", sortable: true },
                { key: "items", label:"Cantidad de Items", sortable: true },
                { key: "total",label:"Total", sortable: true, thStyle: { width: '130px' } },
                { key: "despacho",label:"Despacho", sortable: true,thStyle: { width: '130px' } },
                { key: "usuario", label:"Usuario", sortable: true },
                { key: "estado", label:"Entrega", sortable: true },
                { key: "acciones", label:"Acciones", sortable: true }
            ],
            datos:{
                datos_pago:'',
                datos_envio:'',
            },
            transportista:'',
            seguimiento:'',
            courier:[],
            isLoading:false
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.ordenes.length;
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        detalle(dato){
            this.datos=dato;
            this.$refs['modal-detalle'].show()
        },
        despacho(data){
            this.datos=data;
            this.$refs['modal-despacho'].show()
        },
        enviar(orden_id){//enviar mensaje sobre que está listo el pedido
            let payload = {
                orden_id: orden_id,
            };
            this.isLoading=true
            axios.post('/api/retiro_listo',payload).then(res=>{
                if(res.data.estado){
                    console.log('enviado')
                    this.actualizar()
                    this.$refs['modal-despacho'].hide()
                }
                this.isLoading=false
            })
        },
        transporte(orden_id){ //crear funcion para guardar los datos
            let payload = {
                orden_id: orden_id,
                transportista_id: this.transportista,
                transportista: this.courier.find(cc => cc.id === this.transportista),
                seguimiento: this.seguimiento,
            };
            this.isLoading=true
            axios.post('/api/envio_listo',payload).then(res=>{
                if(res.data.estado){
                    console.log('enviado')
                    this.actualizar()
                    this.$refs['modal-despacho'].hide()
                    this.$toasted.success('Datos guardados correctamente!');
                }
                this.isLoading=false
            })
        },
        async actualizar(){
            this.isLoading=true
            await axios.get('/api/get_ordenes').then(res=>{
                this.ordenes = res.data.ordenes;
                this.courier = res.data.courier;
                this.ordenes.forEach(orden => {
                    try {
                        orden.datos_envio=JSON.parse(orden.datos_envio);
                        orden.datos_pago=JSON.parse(orden.datos_pago);
                    } catch (error) {
                        console.error('Error al analizar JSON:', error);
                    }
                });
                //console.log(this.ordenes);
            })
            this.isLoading=false
        },
        fecha(fechahora){
            var orden = moment(fechahora).format('DD-MM-YYYY HH:mm');
            return orden
        },
        region(dat){
            var reg = this.regiones.filter(item=>item.id ==dat.oregion);
            return reg[0].nombre
        },
        comuna(dat){
            var com = this.comunas.filter(item=>item.id ==dat.ocomuna);
            return com[0].nombre
        },
        finalizar_orden(orden_id){
            let payload = {
                orden_id: orden_id,
            };
            this.isLoading=true
            axios.post('/api/orden_finalizada',payload).then(res=>{
                if(res.data.estado){
                    console.log('finalizado')
                    console.log(res.data.orden)
                    this.actualizar()
                    this.$refs['modal-despacho'].hide()
                    this.$toasted.success('Orden finalizada!');
                }
                this.isLoading=false
            })
        }
    },
    created(){
        this.actualizar();
        
    }
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="loading-overlay" v-if="isLoading">
                            <loading-message  />
                        </div>
                        <h4 class="card-title">Ordenes de Compra</h4>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Mostrar&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;ordenes
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                    <label class="d-inline-flex align-items-center">
                                        Buscar:
                                        <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                class="table-nowrap"
                                :items="ordenes"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                            >
                                <template v-slot:cell(items)="data">
                                    {{data.item.cart_items.length}}
                                </template>
                                <template v-slot:cell(total)="data">
                                    {{ data.item.cart_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                </template>
                                <template v-slot:cell(status)="data">
                                    <p v-if="data.item.status!='AUTHORIZED'">Rechazado</p>
                                    <p v-else>Aprobado</p>
                                </template>
                                <template v-slot:cell(usuario)="data">
                                    {{data.item.email}}
                                </template>
                                <template v-slot:cell(despacho)="data">
                                    <p>{{data.item.datos_envio.tipo}}</p>
                                </template>
                                <template v-slot:cell(estado)="data">
                                    <p>{{data.item.estado}}</p>
                                </template>
                                <template v-slot:cell(acciones)="data">
                                    <b-button size="sm" variant="primary" @click="detalle(data.item)">Ver detalle</b-button>
                                    <b-button size="sm" :variant="data.item.estado=='Finalizado'?'success':'warning'" @click="despacho(data.item)" v-if="data.item.aprobado=='1'">
                                        <p class="m-0 p-0" v-if="data.item.datos_envio.tipo=='Retiro' && data.item.datos_despacho==null">Avisar</p>
                                        <p class="m-0 p-0" v-else-if="data.item.datos_envio.tipo=='Retiro' && data.item.datos_despacho!=''">Retirado</p>
                                        <p class="m-0 p-0" v-else-if="data.item.datos_envio.tipo=='Domicilio' && data.item.datos_despacho!=null">Despachado</p>
                                        <p class="m-0 p-0" v-else>Despachar</p>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                        <b-modal ref="modal-despacho" title="Despacho" hide-footer>
                            <div v-if="datos.datos_envio.tipo=='Retiro' && datos.datos_despacho==null">
                                <b-button variant="primary" @click="enviar(datos.id)">Enviar mensaje para retiro</b-button>
                            </div>
                            <div v-else-if="datos.datos_envio.tipo=='Domicilio' && datos.datos_despacho==null">
                                <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Transportista" label-for="text" >
                                    <select class="form-control" v-model="transportista">
                                        <option value="">Selecciona una opción</option>
                                        <option :value="transporte.id" v-for="transporte in courier" :key="transporte.id">{{transporte.nombre}}</option>
                                    </select> 
                                </b-form-group>
                                <b-form-group id="example text" label-cols-sm="12" label-cols-lg="4" label="Código de seguimiento:" label-for="text">
                                    <b-form-input for="text" placeholder="Ingresar código" v-model="seguimiento"></b-form-input>
                                </b-form-group>
                                <b-button variant="primary" @click="transporte(datos.id)">Guardar</b-button>
                            </div>
                            <div  v-else>
                                <p><b>Retiro:</b> {{ datos.datos_envio.tipo }}</p>
                                <p v-if="datos.datos_envio.tipo=='Retiro'"><b>Estado:</b> {{ datos.datos_despacho }}</p>
                                <p v-if="datos.datos_envio.tipo=='Retiro'"><b>Dirección:</b> Errázuriz 2066, Valdivia</p>
                                <p v-if="datos.datos_envio.tipo=='Domicilio'"><b>Estado:</b> {{ datos.estado }}</p>
                                <p v-if="datos.datos_envio.tipo=='Domicilio'"><b>Dirección:</b> {{ datos.datos_envio.otra_direccion }}, {{ comuna(datos.datos_envio) }}, {{ region(datos.datos_envio) }}</p>
                                <div class="card bg-soft-info" v-if="datos.datos_envio.tipo=='Domicilio'">
                                    <div class="card-body">
                                        <h5 class="text-primary">Datos del envío</h5>
                                        <p><b>Transportista:</b> {{ JSON.parse(datos.datos_despacho)[0] }}</p>
                                        <p><b>Código de seguimiento:</b> {{ JSON.parse(datos.datos_despacho)[1] }}</p>
                                        <p><b>URL:</b><a :href="JSON.parse(datos.datos_despacho)[2]"> {{ JSON.parse(datos.datos_despacho)[0] }}</a></p>
                                    </div>
                                </div>
                                <b-button class="float-right mt-3" v-if="datos.datos_envio.tipo=='Retiro' && datos.estado!='Finalizado'" @click="finalizar_orden(datos.id)" variant="warning">Finalizar Orden</b-button>
                            </div>
                        </b-modal>
                        <b-modal ref="modal-detalle" title="Detalle de la orden" ok-only ok-title="Cerrar">
                            <div class="card bg-soft-info">
                                <div class="card-body row">
                                    <div class="col-md-5">
                                        <p><b>Folio :</b> {{datos.id}}</p>
                                    </div>
                                    <div class="col-md-7">
                                        <p><b>Fecha Compra :</b> {{fecha(datos.created_at)}}</p>
                                    </div>
                                    <div class="col-md-5">
                                        <p v-if="datos.status!='AUTHORIZED'"><b>Estado:</b> Rechazado</p>
                                        <p v-else><b>Estado:</b> Aprobado</p>
                                    </div>
                                    <div class="col-md-7">
                                        <p><b>Total a pagar :</b> {{parseInt(datos.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-centered mb-0 table-nowrap">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Correo usuario
                                            </td>
                                            <td>
                                                {{datos.email}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Rut usuario
                                            </td>
                                            <td>
                                                {{datos.rut}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Teléfono usuario
                                            </td>
                                            <td>
                                                {{datos.telefono}}
                                            </td>
                                        </tr>
                                        <tr >
                                            <td>
                                                Despacho
                                            </td>
                                            <td v-if="datos.datos_envio.tipo=='Retiro'">
                                                Retiro en Local
                                            </td>
                                            <td v-if="datos.datos_envio.tipo=='Domicilio'">
                                                Domicilio
                                            </td>
                                        </tr>
                                        <tr v-if="datos.datos_envio.tipo=='Domicilio' && datos.datos_envio.otra_direccion != null ">
                                            <td>
                                                Dirección de envío
                                            </td>
                                            <td>
                                                {{ datos.datos_envio.otra_direccion }}
                                            </td>
                                        </tr>
                                        <tr v-if="datos.datos_envio.tipo=='Domicilio' && datos.datos_envio.otra_direccion2 != null">
                                            <td>
                                                Departamento o habitación
                                            </td>
                                            <td>
                                                {{ datos.datos_envio.otra_direccion2 }}
                                            </td>
                                        </tr>
                                        <tr v-if="datos.datos_envio.tipo=='Domicilio' && datos.datos_envio.ocomuna != null">
                                            <td>
                                                Comuna
                                            </td>
                                            <td>
                                                {{ comuna(datos.datos_envio) }}
                                            </td>
                                        </tr>
                                        <tr v-if="datos.datos_envio.tipo=='Domicilio'&& datos.datos_envio.oregion != null">
                                            <td>
                                                Región
                                            </td>
                                            <td>
                                                {{ region(datos.datos_envio) }}
                                            </td>
                                        </tr>
                                        
                                    </tbody>
                                </table>
                            </div>
                            <div class="row m-2">
                                <h4>Productos comprados</h4>
                                <div class="col-md-12" v-for="(item,index) in datos.cart_items" :key="index">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <img :src="item.imagen" alt="product-img" title="product-img" class="avatar-sm" />
                                        </div>
                                        <div class="col-md-10">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <p>{{item.nombre}}</p>
                                                    <span class="small-text">sku: {{ item.codigo_var?item.codigo_var:item.codigo }}</span>
                                                </div>
                                                <div class="col-md-3">
                                                    <p>Cantidad: {{item.cantidad}}</p>
                                                    <!-- <input type="text" class="form-control text-center" disabled :value="item.cantidad"> -->
                                                </div>
                                                <div class="col-md-9 text-right">
                                                    <p>$ {{(item.precio*item.cantidad).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="dropdown-divider"></div>
                                </div>
                            </div>
                        </b-modal>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>