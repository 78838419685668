<script>
import Layout from "./base";
import Swal from "sweetalert2";

export default {
    components: { Layout, Swal },
    props:{
        submiturl: {
        type: String,
        required: true
        },
        autherror: {
        type: String,
        required: false,
        default: () => null
        },
        autenticado:'',
        regiones:{},
        comunas:{},
    },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            desc:0,
            envio:null,
            datos_envio:{
                nombre:'',
                rut:'',
                direccion:'',
                direccion2:'',
                region:'',
                comuna:'',
                telefono:'',
                email:'',
                otra_nombre:'',
                otra_nombre:'',
                otra_rut:'',
                otra_direccion:'',
                otra_direccion2:'',
                otra_dir:'',
                otra_region:'',
                otra_comuna:'',
                notas:''
            },
            boton_webpay:false,
            screen:{},
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            promociones:[],
            promo:[],
            cantidad:0,
        };
    },
    computed:{
        cartItems(){
            return this.$store.getters.cartItems;
        },
        cartTotal() {
            return this.$store.getters.cartTotal;
        },
        total(){
            let total=0;
            total= this.cartTotal + this.envio - this.desc
            return total
        },
        comunasFiltradas() {
            if (!this.datos_envio.region) return [];
                return this.comunas.filter(comuna => comuna.region_id == this.datos_envio.region);
        },
        comunasFiltradas_otras() {
            if (!this.datos_envio.otra_region) return [];
                return this.comunas.filter(comuna => comuna.region_id == this.datos_envio.otra_region);
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods:{
        removeFromCart(index) {
            this.$store.dispatch('removeFromCart', index);
            this.revisar_promocion();
        },
        increaseQuantity(index) {
            this.$store.dispatch('increaseQuantity', index);
            this.revisar_promocion();
        },
        decreaseQuantity(index) {
            this.$store.dispatch('decreaseQuantity', index);
            this.revisar_promocion();
        },
        ver_modal_direccion(){
            this.$refs['modal_direccion'].show()
        },
        pagar_webpay(){
            if (this.validarCampos()) {
                this.regiones.forEach(reg => {
                    if (reg.id == this.datos_envio.region) {
                        this.envio = reg.valor;
                    }
                });
                this.boton_webpay=true
                let payload={
                    cartItems:this.$store.getters.cartItems,
                    cartTotal:this.$store.getters.cartTotal,
                    envio:this.envio,
                    desc:this.desc,
                    tipo:'Webpay',
                    rut:this.datos_envio.rut,
                    telefono:this.datos_envio.telefono,
                    email:this.datos_envio.email,
                    datos_envio:this.datos_envio,
                }
                console.log(payload)
                axios.post('/api/nueva_orden',payload).then(res=>{
                    if (res.data.estado) {
                        window.location.href = `/orden/${res.data.orden.uid}`;
                    }else{
                        console.log(res.data.mensaje);
                        this.boton_webpay=false
                    }
                }).catch(error => {
                    if (error.response.status == 422) {
                        const errors = error.response.data.errors;
                        Object.keys(errors).forEach(key => {
                            errors[key].forEach(message => {
                                // Aquí puedes mostrar cada mensaje de error en tu interfaz de usuario
                                console.error(message);
                            });
                        });
                        this.boton_webpay=false
                    } else {
                        console.error(error);
                    }
                })
            } else {
                let camposFaltantes = this.obtenerCamposFaltantes();
                let mensaje = 'Por favor, completa los siguientes campos obligatorios:\n';
                camposFaltantes.forEach(campo => {
                    mensaje += '- ' + campo + '\n';
                });
                confirm(mensaje);
                console.log('Formulario inválido');
            }
        },
        obtenerCamposFaltantes() {
            let camposFaltantes = [];
            let objeto = Object.keys(this.datos_envio)
            Object.keys(this.datos_envio).forEach(campo => {
                if (this.datos_envio[campo].toString().trim() == '' && campo !== 'otra_dir' && campo != 'direccion2' && campo != 'otra_nombre' && campo != 'otra_rut' && campo !== 'otra_direccion' && campo != 'otra_direccion2' && campo != 'otra_dir' && campo != 'otra_region' && campo != 'otra_comuna') {
                    camposFaltantes.push(campo.charAt(0).toUpperCase() + campo.slice(1));
                }
            });
            if(this.datos_envio.otra_dir){
                objeto.forEach(campo => {
                    if (this.datos_envio[campo].toString().trim() == '' && campo != 'otra_dir' && campo != 'otra_direccion2' && campo != 'nombre' && campo != 'rut' && campo != 'direccion' && campo != 'direccion2' && campo != 'region' && campo != 'comuna') {
                        camposFaltantes.push(campo.charAt(0).toUpperCase() + campo.slice(1));
                    }
                });
            }
            return camposFaltantes;
        },
        validarCampos() {
            return (
                this.datos_envio.nombre.trim() !== '' &&
                this.datos_envio.rut.trim() !== '' &&
                this.datos_envio.direccion.trim() !== '' &&
                this.datos_envio.region !== '' &&
                this.datos_envio.comuna !== '' &&
                this.datos_envio.telefono.trim() !== '' &&
                this.datos_envio.email.trim() !== ''
                // Agrega más validaciones aquí para otros campos si es necesario
            );
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
            console.log(this.windowWidth);
            console.log(this.windowHeight);
        },
        sub(item){
            let valor = item.precio*item.cantidad;
            return valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        encontrarProductoMenorPrecio(marca) {
            const resultado = this.productos.reduce((acumulador, productoActual, indiceActual) => {
                if (productoActual.marca == marca) {
                if (acumulador == null || productoActual.precio < acumulador.producto.precio) {
                    return { producto: productoActual, indice: indiceActual };
                }
                }
                return acumulador;
            }, null);
            
            return resultado ? resultado : { producto: null, indice: null };
        },
        revisar_promocion() {
            axios.get('/api/get_promociones').then(res => {
                this.promociones = res.data.promociones;
                this.promo = this.promociones.filter(p => p.estado == 1);

                const carro = JSON.parse(localStorage.getItem("cart_sin_promo")) || JSON.parse(localStorage.getItem("cart"));
                
                if (this.promo.length > 0 && this.promo[0].tipo == 'comprexllevey') {
                    if (this.promo[0].ambito == 'marca') {
                        localStorage.setItem("cart_sin_promo", JSON.stringify(carro));
                        let carro_promo = carro.filter(marca => marca.marca == this.promo[0].detalle_ambito);
                        this.cantidad = carro_promo.reduce((total, producto) => total + parseInt(producto.cantidad), 0);
                        let prod_gratis = Math.floor(this.cantidad / parseInt(this.promo[0].cantidad));

                        if (prod_gratis > 0) {
                            const promocionAplicada = carro.find(p => p.promocion != '');
                            if (!promocionAplicada) {
                                if (carro.length == 1 && this.cantidad >= this.promo[0].cantidad) {
                                    let regalo = {
                                        cantidad: parseInt(prod_gratis),
                                        codigo_var: carro[0].codigo_var,
                                        imagen: carro[0].imagen,
                                        marca: carro[0].marca,
                                        nombre: carro[0].nombre,
                                        precio: 0,
                                        precio_normal: 0,
                                        precio_rebajado: 0,
                                        producto_id: carro[0].producto_id,
                                        stock: carro[0].stock,
                                        tipo: carro[0].tipo,
                                        titulo: carro[0].nombre,
                                        promocion: carro[0].producto_id
                                    }
                                    carro.push(regalo);
                                    carro[0].cantidad -= prod_gratis;
                                    localStorage.setItem("cart", JSON.stringify(carro));
                                } else {
                                    while(prod_gratis>0){
                                        let productoMenorPrecio = carro_promo.reduce((productoMenorPrecio, productoActual) => {
                                            return parseInt(productoActual.precio_normal) < parseInt(productoMenorPrecio.precio_normal) && parseInt(productoActual.precio_normal)>0  ? productoActual : productoMenorPrecio;
                                        });
    
                                        if(productoMenorPrecio.cantidad >= prod_gratis){
                                            productoMenorPrecio.cantidad-=prod_gratis;
                                            let regalo = {
                                                cantidad: parseInt(prod_gratis),
                                                codigo_var: productoMenorPrecio.codigo_var,
                                                imagen: productoMenorPrecio.imagen,
                                                marca: productoMenorPrecio.marca,
                                                nombre: productoMenorPrecio.nombre,
                                                precio: 0,
                                                precio_normal: 0,
                                                precio_rebajado: 0,
                                                producto_id: productoMenorPrecio.producto_id,
                                                stock: productoMenorPrecio.stock,
                                                tipo: productoMenorPrecio.tipo,
                                                titulo: productoMenorPrecio.nombre,
                                                promocion: productoMenorPrecio.producto_id
                                            };
                                            carro.push(regalo);
                                            prod_gratis=0
                                        }else{
                                            prod_gratis-=productoMenorPrecio.cantidad
                                            let regalo = {
                                                cantidad: parseInt(productoMenorPrecio.cantidad),
                                                codigo_var: productoMenorPrecio.codigo_var,
                                                imagen: productoMenorPrecio.imagen,
                                                marca: productoMenorPrecio.marca,
                                                nombre: productoMenorPrecio.nombre,
                                                precio: 0,
                                                precio_normal: 0,
                                                precio_rebajado: 0,
                                                producto_id: productoMenorPrecio.producto_id,
                                                stock: productoMenorPrecio.stock,
                                                tipo: productoMenorPrecio.tipo,
                                                titulo: productoMenorPrecio.nombre,
                                                promocion: productoMenorPrecio.producto_id
                                            };
                                            carro.push(regalo);
                                            productoMenorPrecio.cantidad=0;
                                        }
                                        if (productoMenorPrecio.cantidad <= 0) {
                                            const indiceProducto = carro.findIndex(producto => producto.producto_id == productoMenorPrecio.producto_id && producto.cantidad == 0);
                                            carro.splice(indiceProducto, 1);
                                            carro_promo = carro.filter(marca => marca.marca == this.promo[0].detalle_ambito);
                                        }

                                    }

                                    localStorage.setItem("cart", JSON.stringify(carro));
                                }
                                // Puedes usar this.$forceUpdate(); si es necesario en lugar de recargar
                                //this.$forceUpdate();
                                this.$store.dispatch('initializeStore');
                            }
                        }

                    }
                }
            });
        }
    },
    created() {
        this.regiones = JSON.parse(this.regiones);
        this.comunas = JSON.parse(this.comunas);
        // if(this.direccion!==''){
        //     this.direccion=JSON.parse(this.direccion)
        //     this.datos_envio.direccion=this.direccion.direccion
        //     this.datos_envio.direccion2=this.direccion.direccion2
        //     this.datos_envio.region=this.direccion.region
        //     this.datos_envio.comuna=this.direccion.comuna
        // }
        console.log(this.autenticado==''?'No logueado':'Logueado')
        console.log(this.regiones[0].id)
        this.screen=window.screen
        this.handleResize()
        this.revisar_promocion();
        //console.log(this.screen);
    },
};
</script>
<template>
    <Layout :submiturl='submiturl' :autherror="autherror" :autenticado="autenticado">
        <div class="row">
            <div class="col-xl-8">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive" v-if="windowWidth>576">
                            <table class="table table-centered mb-0 table-nowrap">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Producto</th>
                                        <th>Producto Desc.</th>
                                        <!-- <th>Precio</th> -->
                                        <th>Cantidad</th>
                                        <th colspan="2">SubTotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item,index) in cartItems" :key="index">
                                        <td>
                                            <img :src="item.imagen" alt="product-img" title="product-img" class="avatar-md" />
                                        </td>
                                        <td>
                                            <h5 class="font-size-14 text-truncate">
                                                <a :href="'/producto/'+item.producto_id" class="text-dark">{{item.nombre}}</a>
                                            </h5>
                                            <p class="mb-0" v-if="item.tipo=='Variable'">
                                                <span class="font-weight-medium">{{item.titulo}}</span>
                                            </p>
                                        </td>
                                        <!-- <td>$ {{item.precio}}</td> -->
                                        <td>
                                            <div style="display: flex;">
                                                <b-button variant="primary" size="sm" @click="decreaseQuantity(index)" :disabled="item.precio=='0'?true:false">-</b-button>
                                                <p class="m-0 mx-3 mt-2">{{item.cantidad}}</p>
                                                <b-button variant="primary" size="sm" @click="increaseQuantity(index)" :disabled="item.precio=='0'?true:false">+</b-button>
                                            </div>
                                        </td>
                                        <td>$ {{sub(item)}}</td>
                                        <td class="text-center">
                                            <b-button size="sm" variant="danger" @click="removeFromCart(index)" v-if="item.precio!='0'"><i class="mdi mdi-trash-can font-size-14"></i></b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else>
                            <div class="mt-4" v-for="(item,index) in cartItems" :key="index">
                                <h4>Producto {{ index+1 }}</h4>
                                <table class="table mb-0" >
                                    <tbody>
                                        <tr>
                                            <td>Producto</td>
                                            <td>
                                                <img :src="item.imagen" alt="product-img" title="product-img" class="avatar-md" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Producto Desc.</td>
                                            <td>
                                                <h5 class="font-size-14 text-truncate">
                                                    <a :href="'/producto/'+item.producto_id" class="text-dark">{{item.nombre}}</a>
                                                </h5>
                                                <p class="mb-0" v-if="item.tipo=='Variable'">
                                                    <span class="font-weight-medium">{{item.titulo}}</span>
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Cantidad</td>
                                            <td>
                                                <div style="display: flex;">
                                                    <b-button variant="primary" size="sm" @click="decreaseQuantity(index)" :disabled="item.precio=='0'?true:false">-</b-button>
                                                    <p class="m-0 mx-3">{{item.cantidad}}</p>
                                                    <b-button variant="primary" size="sm" @click="increaseQuantity(index)" :disabled="item.precio=='0'?true:false">+</b-button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>SubTotal</td>
                                            <td>$ {{sub(item) }}</td>
                                        </tr>
                                        <tr>
                                            <td>Eliminar del carro</td>
                                            <td class="text-center">
                                                <b-button size="sm" variant="danger" @click="removeFromCart(index)" :disabled="item.precio=='0'?true:false"><i class="mdi mdi-trash-can font-size-14"></i></b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- <div class="row mt-4">
                            <div class="col-sm-6">
                                <a href="/filtrar" class="btn btn-secondary btn-sm">
                                    <i class="mdi mdi-arrow-left mr-1"></i> Continuar Comprando
                                </a>
                            </div>
                            <div class="col-sm-6">
                                <div class="text-sm-right mt-2 mt-sm-0">
                                    <a @click="ver_modal_direccion()" class="btn btn-success">
                                        <i class="mdi mdi-cart-arrow-right mr-1"></i> Realizar el pedido
                                    </a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title mb-3">Resumen de Compra</h4>
                        <!-- <div class="dropdown-divider"></div>
                        <div>
                            <p>Si tienes un código de descuento utilizalo haciendo click Aqui</p>
                        </div> -->
                        <div class="dropdown-divider"></div>
                        <!-- <div>
                            <p>Calcular tiempos y costo de envío</p>
                            <div>
                                <b-tabs content-class="mt-3">
                                    <b-tab title="Entregar" active>
                                        <div class="row">
                                            <div class="col-md-12 m-3">
                                                <b-form-group id="example text1" label-cols-sm="12" label-cols-lg="6" label="Región:" label-for="text">
                                                    <select id="region" class="custom-select custom-select-sm" v-model="datos_envio.region">
                                                        <option value="" disabled selected>Selecciona una opción</option>
                                                        <option v-for="region in regiones" :key="region.id" :value="region.id">{{ region.nombre }}</option>
                                                    </select>
                                                </b-form-group>
                                                <b-form-group id="example text2" label-cols-sm="12" label-cols-lg="6" label="Comuna:" label-for="text">
                                                    <select id="comuna" class=" custom-select custom-select-sm" v-model="datos_envio.comuna" :disabled="datos_envio.region==''">
                                                        <option value="" disabled selected>Selecciona una opción</option>
                                                        <option v-for="comuna in comunasFiltradas" :key="comuna.id" :value="comuna.id">{{ comuna.nombre }}</option>
                                                    </select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        
                                    </b-tab>
                                    <b-tab title="Recoger">
                                        <div class="text-center">
                                            <p>Punto de Retiro: </p>
                                            <p>Vamproden</p>
                                            <p>Errázuriz 2066, Valdivia</p>
                                            </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div> -->
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <tbody>
                                    <tr>
                                        <td>Total :</td>
                                        <td>$ {{ cartTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</td>
                                    </tr>
                                    <tr v-if="desc!=0">
                                        <td>Descuento :</td>
                                        <td>- $ 157</td>
                                    </tr>
                                    <tr >
                                        <td>Envio :</td>
                                        <td v-if="envio==null">Sin datos de envio</td>
                                        <td v-else>${{envio}}</td>
                                    </tr>
                                    <tr>
                                        <th>Total :</th>
                                        <th>$ {{total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <b-button class="mb-3" variant="primary" pill block href="/checkout">Comprar</b-button>
                                <a href="/filtrar" >
                                    Seguir Comprando
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end card -->
            </div>
            <!-- <b-modal id="modal_direccion" ref="modal_direccion" title="Detalles de envio" ok-only ok-title="atras">
                <div>
                    <label class="font-size-16">Detalles de facturación:</label>
                    <form class="form-horizontal" ref="form">
                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Nombre o Razón Social:" label-for="text">
                            <b-form-input for="text" value="" v-model="datos_envio.nombre" required></b-form-input>
                        </b-form-group>
                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Apellidos (opcional)" label-for="text">
                            <b-form-input for="text" value="" v-model="datos_envio.apellidos" required></b-form-input>
                        </b-form-group>
                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Rut de Facturación" label-for="text">
                            <b-form-input for="text" value="" v-model="datos_envio.rut" placeholder="11.111.111-1"></b-form-input>
                        </b-form-group>
                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Dirección" label-for="text">
                            <b-form-input for="text" value="" v-model="datos_envio.direccion" placeholder="Número de la casa y nombre de la calle"></b-form-input>
                            <b-form-input for="text" value="" v-model="datos_envio.direccion2" placeholder="Apartamento, habitación, etc. (opcional)"></b-form-input>
                        </b-form-group>
                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Región y comuna" label-for="text">
                            <select id="region" v-model="datos_envio.region">
                                <option value="" disabled selected>Selecciona una opción</option>
                                <option v-for="region in regiones" :key="region.id" :value="region.id">{{ region.nombre }}</option>
                            </select>
                            <select id="comuna" v-model="datos_envio.comuna" v-if="datos_envio.region">
                                <option value="" disabled selected>Selecciona una opción</option>
                                <option v-for="comuna in comunasFiltradas" :key="comuna.id" :value="comuna.id">{{ comuna.nombre }}</option>
                            </select>
                        </b-form-group>
                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Teléfono" label-for="text">
                            <b-form-input for="text" value="+569 " v-model="datos_envio.telefono" placeholder="+569 1111 111"></b-form-input>
                        </b-form-group>
                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Dirección de correo electrónico" label-for="text">
                            <b-form-input for="text" value="" v-model="datos_envio.email" placeholder=""></b-form-input>
                        </b-form-group>
                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="¿Enviar a una dirección diferente?" label-for="text">
                            <input id="checkbox" type="checkbox" name="¿Enviar a una dirección diferente?" v-model="datos_envio.otra_dir" value="0">
                        </b-form-group>
                        <div v-if="datos_envio.otra_dir">
                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Nombre:" label-for="text">
                                <b-form-input for="text" value="" v-model="datos_envio.otra_nombre"></b-form-input>
                            </b-form-group>
                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Rut de Facturación" label-for="text">
                                <b-form-input for="text" value="" v-model="datos_envio.otra_rut" placeholder="11.111.111-1"></b-form-input>
                            </b-form-group>
                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Dirección" label-for="text">
                                <b-form-input for="text" value="" v-model="datos_envio.otra_direccion" placeholder="Número de la casa y nombre de la calle"></b-form-input>
                                <b-form-input for="text" value="" v-model="datos_envio.otra_direccion2" placeholder="Apartamento, habitación, etc. (opcional)"></b-form-input>
                            </b-form-group>
                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Región y comuna" label-for="text">
                                <select id="region" v-model="datos_envio.otra_region">
                                    <option value="" disabled selected>Selecciona una opción</option>
                                    <option v-for="region in regiones" :key="region.id" :value="region.id">{{ region.nombre }}</option>
                                </select>
                                <select id="comuna" v-model="datos_envio.otra_comuna" v-if="datos_envio.otra_region" placeholder="Selecciona una opción">
                                    <option value="" disabled selected>Selecciona una opción</option>
                                    <option v-for="comuna in comunasFiltradas_otras" :key="comuna.id" :value="comuna.id">{{ comuna.nombre }}</option>
                                </select>
                            </b-form-group>
                        </div>
                        <b-form-group id="example text" label-cols-sm="12" label-cols-lg="3" label="Notas del pedido (opcional)" label-for="text">
                            <textarea name="order_comments" v-model="datos_envio.notas" class="input-text " id="order_comments" placeholder="Notas sobre tu pedido, por ejemplo, notas especiales para la entrega." rows="4" cols="25" spellcheck="false" data-gramm="false"></textarea>
                        </b-form-group>
        
                        <div class="text-right">
                            <b-button variant="info" :disabled="boton_webpay" @click="pagar_webpay()">Pagar con webpay</b-button>
                        </div>
                    </form>
                </div>
            </b-modal> -->
        </div>
        <!-- end row -->
    </Layout>
</template>