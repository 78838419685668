<script>
export default {
    props: {
        titulo: '',
        productos: [],
    },
    data() {
        return {
            windowWidth3: window.innerWidth,
            currentIndex: 0,
            itemsPerPage: 4,
            intervalId: null,
        };
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.startAutoScroll(); // Inicia el cambio automático
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
        clearInterval(this.intervalId);
    },
    computed: {
        displayedProducts() {
            return this.productos.slice(this.currentIndex, this.currentIndex + this.itemsPerPage);
        },
        hasNext() {
            return this.currentIndex + this.itemsPerPage < this.productos.length;
        },
        hasPrev() {
            return this.currentIndex > 0;
        },
    },
    methods: {
        handleResize() {
            this.windowWidth3 = window.innerWidth;
        },
        next() {
            if (this.hasNext) {
                this.currentIndex += this.itemsPerPage;
            }else {
                this.currentIndex = 0; // Reinicia al principio si llega al final
            }
        },
        prev() {
            if (this.hasPrev) {
                this.currentIndex -= this.itemsPerPage;
            }else {
                this.currentIndex = Math.max(this.productos.length - this.itemsPerPage, 0); // Va al final si está en el principio
            }
        },
        startAutoScroll() {
            this.intervalId = setInterval(() => {
                this.next();
            }, 5000); // Cambia cada 3 segundos
        },
    },
};
</script>

<template>
    <div class="mb-5 pb-4">
        <h3 class="pl-3 pt-4">{{ titulo }}</h3>
        <div :class="windowWidth3 > 576 ? 'product-container': 'product-container '">
            <div 
                v-for="(producto, index) in displayedProducts" 
                :key="index" 
                :class="windowWidth3 > 576 ? 'product-card' : 'product-sm-card'"
            >
                <div class="border pt-3 pb-3 text-center position-relative">
                    <div v-if="producto.promocion" class="promotion-label">Promoción</div>
                    <img 
                        :src="'/images/productos_web/producto-sin-imagen.png'" 
                        alt 
                        :class="windowWidth3 > 576 ? 'rounded avatar-3xl' : 'rounded avatar-lg'" 
                        v-if="!producto.foto_dir"
                    />
                    <img 
                        :src="producto.foto_dir" 
                        alt 
                        :class="windowWidth3 > 576 ? 'rounded avatar-3xl' : 'rounded avatar-lg'" 
                        v-else
                    />
                </div>
                <p class="pt-2 m-0 text-danger font-size-12" v-if="producto.precio_rebajado != null && producto.precio_rebajado != 0">
                    Oferta - {{ producto.porcentaje }}% OFF
                </p>
                <p class="pt-2 m-0 text-danger font-size-12" v-else><br></p>
                <h2 class="product-title">{{ producto.nombre }}</h2>
                <span v-if="producto.tipo == 'Simple'">
                    <p class="product-price" v-if="producto.precio_rebajado != null">
                        <del class="text-secondary">${{ producto.precio_normal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</del> - ${{ producto.precio_rebajado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                    </p>
                    <p class="product-price" v-else-if="producto.precio_normal != null">$ {{ producto.precio_normal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</p>
                </span>
                <span v-else-if="producto.tipo == 'Variable'">
                    <p class="product-price" v-if="producto.precio_rebajado != 0">Desde $ {{ producto.precio_rebajado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</p>
                    <p class="product-price" v-else-if="producto.precio_normal != null">Desde $ {{ producto.precio_normal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}</p>
                </span>
                <div class="m-0 p-0">
                    <p class="float-right text-primary font-size-12 m-0 p-0"><b>{{ producto.nombre_marca }}</b></p>
                </div>
                <div class="mt-auto pt-4 p-0 m-0 card-footer" style="background-color: transparent;">
                    <b-button variant="primary" block pill :href="'/producto/' + producto.id">Ver producto</b-button>
                </div>
            </div>
        </div>
        <div class="carousel-controls justify-content-center">
            <b-button 
                variant="primary" 
                :disabled="!hasPrev" 
                @click="prev"><
            </b-button>
            <b-button 
                variant="primary" 
                :disabled="!hasNext" 
                @click="next">>
            </b-button>
        </div>
    </div>
</template>

<style>
.product-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px; /* Espacio entre el carrusel y los controles */
}

.product-card,
.product-sm-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.product-card {
    width: 250px;
    margin: 20px;
}

.product-sm-card {
    width: 150px;
    margin: 10px;
}

.product-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

.product-price {
    font-size: 14px;
    font-weight: bold;
    color: #556ee6;
    margin-top: auto;
}

.carousel-controls {
    position: absolute;
    left: 47%;
    display: flex;
    justify-content: center; /* Centra los botones */
    margin-top: 20px;
}

.carousel-controls b-button {
    margin: 0 10px; /* Espaciado entre botones */
}
</style>
