<script>
import Layout from "./base";
import Carousel from "../componentes/carousel";
import Productos from "../componentes/productos";
import { sampleSize } from 'lodash';
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import Carousel_productos from "../componentes/carousel_productos";
/**
 * Starter component
 */
export default {
    components: { 
        Layout, 
        Carousel, 
        Productos, 
        Hooper,
        Slide,
        Carousel_productos
    },
    props:{
        submiturl: {
        type: String,
        required: true
        },
        autherror: {
        type: String,
        required: false,
        default: () => null
        },
        autenticado:''
    },
    data() {
        return {
            title: "Starter Page",
            items: [
                {
                text: "Utility",
                href: "/"
                },
                {
                text: "Starter Page",
                active: true
                }
            ],
            promociones:[],
            promo:[],
            destacados:[],
            slides:[],
            slidesizq:[],
            slidesder:[],
            ofertas:[],
            windowWidth2: window.innerWidth,
            windowHeight2: window.innerHeight
        };
    },
    computed:{
        style_slide(){
            if (this.slides.lenght>1) {
                return 'width:50%;height:auto;'
            }
            else{
                return 'width:100%;height:auto;'
            }
        }
    },
    created(){
        axios.get('/api/get_slide_publicadas').then(res=>{
            this.slides=res.data.slides;
            this.slidesizq = res.data.slidesIz;
            this.slidesder = res.data.slidesDer;
        });
        axios.get('/api/get_productos_web_destacados').then(res=>{
            // this.promociones=res.data.productos;
            this.destacados = res.data.productos;
            this.promociones = res.data.productos.filter(prod=>prod.nombre_marca !='KERR' && prod.nombre_marca !='ULTRADENT');
            this.promo = this.getRandomElements(this.promociones, 12);
            
        });
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    }, 
    methods:{
        handleResize() {
            this.windowWidth2 = window.innerWidth;
            this.windowHeight2 = window.innerHeight;
        },
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]]; // Intercambia los elementos
            }
            return array;
        },
        getRandomElements(array, numberOfElements) {
            const shuffledArray = this.shuffleArray([...array]); // Crea una copia y la mezcla
            return shuffledArray.slice(0, numberOfElements); // Devuelve los primeros N elementos
        }
    }
};
</script>
<template>
    <Layout :submiturl='submiturl' :autherror="autherror" :autenticado="autenticado">
        <div :class="windowWidth2<576 ? 'row mt-10':'row'" >
            <Carousel class="pb-5" :slides="slides" v-if="slides.length>0"/>
        </div>
        <div class="row">
            <div class="col-md-6 cont mb-3" v-if="slidesizq!=null">
                <a :href="'https://'+slidesizq.link" target="_blank" rel="noopener noreferrer" v-if="slidesizq.link!=null">
                    <img :src="slidesizq.image" alt class="cont_img" />
                </a>
                <img :src="slidesizq.image" alt class="cont_img" v-else/>
            </div>
            <div class="col-md-6 cont mb-3" v-if="slidesder!=null">
                <a :href="'https://'+slidesder.link" target="_blank" rel="noopener noreferrer" v-if="slidesder.link!=null">
                    <img :src="slidesder.image" alt class="cont_img"  />
                </a>
                <img :src="slidesder.image" alt class="cont_img" v-else/>
            </div>
        </div>
        <div class="row justify-content-center" >
            <Carousel_productos :titulo="'PRODUCTOS DESTACADOS ULTRADENT'" :productos="getRandomElements(destacados.filter(prod=>prod.nombre_marca == 'ULTRADENT'),12)" />
        </div>
        <div class="row justify-content-center" >
            <Carousel_productos :titulo="'PRODUCTOS DESTACADOS KERR'" :productos="getRandomElements(destacados.filter(prod=>prod.nombre_marca == 'KERR'),12)"/>
        </div>
        <div class="row justify-content-center" v-if="promociones.length>0">
            <Productos :titulo="'PRODUCTOS RECOMENDADOS'" :productos="promo"/>
        </div>
        <!-- <div class="row" v-if="ofertas.length>0">
            <Productos :titulo="'OFERTAS'" :productos="ofertas"/>
        </div> -->
    </Layout>
</template>

<style>
.cont{
    width: 300px;
    height: auto;
}
.cont_img{
    object-fit: cover;
    width:100%;
    height:100%;
}
@media (max-width: 992px) {
    .cont{
        width: 100%;
        height: 100%;
    }
    .cont_img{
        object-fit: cover;
        width:100%;
        height:100%;
    }
}
</style>