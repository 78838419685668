<script>
import simplebar from "simplebar-vue";

/**
 * Nav-bar Component
 */
export default {
    components: { simplebar },
    data() {
        return {
            enConstruccion: false, // Estado del switch, por defecto está en false
            message: null,
        };
    },
    created() {
        this.fetchEnConstruccion(); // Cargar el estado inicial de "En Construcción"
    },
    methods: {
        fetchEnConstruccion() {
            axios.get('/api/config/en_construccion')
                .then(response => {
                    this.enConstruccion = response.data.en_construccion;
                })
                .catch(error => {
                    console.error("Error al obtener la configuración", error);
                });
        },
        // Cambiar el estado de "En Construcción"
        toggleEnConstruccion() {
            axios.post('/api/config/en_construccion', {
                en_construccion: this.enConstruccion
            })
                .then(response => {
                    this.message = response.data.message;
                })
                .catch(error => {
                    console.error("Error al actualizar la configuración", error);
                    this.message = "Error al actualizar la configuración";
                });
        },
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        initFullScreen() {
            document.body.classList.toggle("fullscreen-enable");
            if (
                !document.fullscreenElement &&
                /* alternative standard method */ !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    );
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        }
    }
};
</script>

<template>
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box">
                    <a href="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="/archivos_base/logoblanco.png" alt height="22" />
                        </span>
                        <span class="logo-lg">
                            <img src="/archivos_base/logoblanco.png" alt height="17" />
                        </span>
                    </a>
                    <a href="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="/archivos_base/logoblanco.png" alt height="10" />
                        </span>
                        <span class="logo-lg">
                            <img src="/archivos_base/logoblanco.png" alt height="40" />
                        </span>
                    </a>
                </div>
                <button id="vertical-menu-btn" type="button" class="btn btn-sm px-3 font-size-16 header-item"
                    @click="toggleMenu">
                    <i class="fa fa-fw fa-bars"></i>
                </button>
            </div>
            <div class="d-flex align-items-center">
                <b-form-checkbox v-model="enConstruccion" switch @change="toggleEnConstruccion" :value="true"
                    :unchecked-value="false" class="ml-3" size="lg">
                    Página en Construcción
                </b-form-checkbox>
                <!-- Menú de usuario -->
                <b-dropdown right variant="black" toggle-class="header-item">
                    <template v-slot:button-content>
                        <i class="bx bx-user font-size-18 align-middle mr-1"></i>
                        <span class="d-none d-xl-inline-block ml-1">{{ user.name }}</span>
                        <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </template>
                    <!-- item -->
                    <a class="dropdown-item text-danger" href="/logout">
                        <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Salir
                    </a>
                </b-dropdown>

            </div>
        </div>
    </header>
</template>
