<script>

export default {
    data() {
        return{
            windowWidth5: window.innerWidth,
            windowHeight5: window.innerHeight
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth5 = window.innerWidth;
            this.windowHeight5 = window.innerHeight;
        },
    }
}
</script>

<template>
    <footer class="footer2">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-3 mb-3">
                    <div class="pl-4 mt-5">
                        <a href="https://www.vamproden.cl/" class="logo logo-dark">
                            <span class="logo-lg">
                            <img src="/archivos_base/logoblanco.png" alt height="70" />
                            </span>
                        </a>
                    </div>
                    <div class="d-sm-block d-lg-none text-center">
                        <a href="https://www.vamproden.cl/" class="logo logo-dark text-center">
                            <span class="logo-sm">
                            <img src="/archivos_base/logoblanco.png" alt :height="windowWidth5>1024 ? '70':'50'" />
                            </span>
                        </a>
                    </div>
                </div>
                <div class="col-md-3 text-center">
                    <p class="text-white font-size-16"><i class="text-white bx bx-time-five"></i> Horario de atención:</p>
                    <p class="text-white font-size-16 pb-0 mb-0">Lunes - Jueves</p>
                    <p class="text-white font-size-16 pt-0 mt-0 pb-0 mb-0">9:00 - 18:30</p>
                    <p class="text-white font-size-16 pt-0 mt-0 pb-0 mb-0">Viernes</p>
                    <p class="text-white font-size-16 pt-0 mt-0">09:00 - 17:30</p>
                </div>
                <div class="col-md-3 mb-3 text-center">
                    <!-- <label class="text-white font-size-16">Información de contacto:</label> -->
                    <p class="text-white font-size-16"><i class="text-white bx bxs-compass"></i> Errázuriz 2066, Valdivia</p>
                    <p class="text-white font-size-16"><i class="text-white bx bx-mobile"></i> 63 2 219881 - 63 2 204385</p>
                    <p class="text-white font-size-16"><i class="text-white bx bxs-envelope"></i> contacto@vamproden.cl</p>
                    <b-button class="m-0 p-0" variant="none" href="/info/terminoscondiciones" target="_blank">
                        <p class="text-white font-size-16"><i class="text-white bx bx-receipt"></i> Términos y Condiciones</p>
                    </b-button>
                    <!-- <div class="text-sm-right d-none d-sm-block">Design & Develop by Themesbrand</div> -->
                </div>
                <div class="col-md-3 mb-3">
                    <!-- <p class="text-white font-size-16">Síguenos en nuestras redes sociales:</p> -->
                    <div id="logos">
                        <a class="logo_footer" href="https://www.facebook.com/profile.php?id=100063517005609" target="_blank"><i class="bx bxl-facebook-square font-size-40"></i></a>
                        <a class="logo_footer" href="https://www.instagram.com/vamproden.chile/" target="_blank"><i class="bx bxl-instagram font-size-40"></i></a>
                    </div>
                    <div :class="windowWidth5<1024 ? 'text-center':''">
                        <a href="https://www.webpay.cl/company/27935" target="_blank">
                            <img src="/archivos_base/webpay_blanco.webp" width="200vw" height="auto">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<style scoped>

</style>