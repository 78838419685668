import { forEach } from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        cart: []
    },
    mutations: {
        loadCartSinPromo(state) {
            const cartSinPromo = localStorage.getItem('cart_sin_promo');
            if (cartSinPromo) {
                state.cart = JSON.parse(cartSinPromo);
                localStorage.removeItem("cart_sin_promo");
            }
        },
        addToCart(state, product) {
            this.commit('loadCartSinPromo');
            const existingProduct = state.cart.find(item => item.producto_id === product.producto_id && item.promocion=='');
            const existingPromoProduct = state.cart.find(item => item.producto_id === product.producto_id && item.promocion!='');
            if((existingProduct || existingPromoProduct) && product.tipo=='Variable'){
                if(existingProduct && !existingPromoProduct){
                    if(product.promocion==''){
                        const existingVariacion = state.cart.find(item => item.variacion_id === product.variacion_id);
                        if (existingVariacion) {
                            if ((existingProduct.cantidad + product.cantidad)<product.stock ) {
                                existingProduct.cantidad = existingProduct.cantidad + product.cantidad;
                            }else{
                                existingProduct.cantidad = product.stock;
                            }
                        }else{
                            state.cart.push(product);
                        }
                    }else if(product.promocion!=''){
                        state.cart.push(product);
                    }
                }else if(!existingProduct && existingPromoProduct){
                    state.cart.push(product);
                }else if(existingProduct && existingPromoProduct){
                    existingPromoProduct.cantidad = existingPromoProduct.cantidad + 1
                }
            }else if((existingProduct || existingPromoProduct) && product.tipo=='Simple'){
                if(existingProduct && !existingPromoProduct){
                    if(product.promocion==''){
                        if ((existingProduct.cantidad + product.cantidad)<product.stock ) {
                            existingProduct.cantidad = existingProduct.cantidad + product.cantidad;
                        }else{
                            existingProduct.cantidad = product.stock;
                        }
                    }else if(product.promocion!=''){
                        state.cart.push(product);
                    }
                }else if(!existingProduct && existingPromoProduct){
                    state.cart.push(product);
                }else if(existingProduct && existingPromoProduct){
                    existingPromoProduct.cantidad = existingPromoProduct.cantidad + 1
                }
            }else{
                state.cart.push(product);
            }
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        removeFromCart(state, index) {
            this.commit('loadCartSinPromo');
            state.cart = state.cart.filter(elemento=>elemento.promocion!=state.cart[index].producto_id);
            state.cart.splice(index, 1);
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        increaseQuantity(state, index) {
            this.commit('loadCartSinPromo');
            if (state.cart[index].cantidad<state.cart[index].stock) {
                state.cart[index].cantidad++;
                localStorage.setItem('cart', JSON.stringify(state.cart));
            }else{
                localStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        decreaseQuantity(state, index) {
            this.commit('loadCartSinPromo');
            if (state.cart[index].cantidad > 1) {
                state.cart[index].cantidad--;
                localStorage.setItem('cart', JSON.stringify(state.cart));
            }else{
                localStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        initializeStore(state) {
            if (localStorage.getItem('cart')) {
                state.cart = JSON.parse(localStorage.getItem('cart'));
            }
        }
    },
    actions: {
        addToCart(context, product) {
            context.commit('addToCart', product);
        },
        removeFromCart(context, index) {
            context.commit('removeFromCart', index);
        },
        increaseQuantity(context, index) {
            context.commit('increaseQuantity', index);
        },
        decreaseQuantity(context, index) {
            context.commit('decreaseQuantity', index);
        },
        initializeStore(context) {
            context.commit('initializeStore');
        }
    },
    getters: {
        cartItems: state => state.cart,
        cartTotal: state => {
            return state.cart.reduce((total, item) => total + (item.precio*item.cantidad), 0);
        }
    }
});