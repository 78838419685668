<script>
import simplebar from "simplebar-vue";
import Login from "../views/account/login";
import { mapGetters } from 'vuex';
import Swal from "sweetalert2";

export default {
    components: {
        simplebar,
        Login
    },
    props:{
        submiturl: {
            type: String,
            required: true
        },
        autherror: {
            type: String,
            required: false,
            default: () => null
        },
        autenticado:'',
    },
    data() {
        return {
            categorias:[],
            marcas:[],
            categorias_largo:0,
            marcas_largo:0,
            filter:'',
            productos1:[],
            productos2:[],
            panelVisible:false,
            windowWidth1: window.innerWidth,
            windowHeight1: window.innerHeight,
            contacto:{
                nombre:'',
                mail:'',
                asunto:'',
                mensaje:''
            }
        };
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    watch:{
        filter(newValue){
            if (newValue=='') {
                this.productos2=[];
            }else{
                this.productos2= this.productos1.filter(producto => producto.nombre.toLowerCase().includes(newValue.toLowerCase()));
                if (this.productos2.length==0) {
                    this.productos2= this.productos1.filter(producto => producto.nombre_marca.toLowerCase().includes(newValue.toLowerCase()));
                }
                if (this.productos2.length==0) {
                    this.productos2= this.productos1.filter(producto => producto.codigo.toLowerCase().includes(newValue.toLowerCase()));
                }
            }
        },
    },
    computed:{
        ...mapGetters(['cartItems'])
    },
    methods: {
        handleResize() {
            this.windowWidth1 = window.innerWidth;
            this.windowHeight1 = window.innerHeight;
        },
        toggleRightSidebar() {
        this.$parent.toggleRightSidebar();
        },
        toggleMenu() {
        let element = document.getElementById("topnav-menu-content");
        element.classList.toggle("show");
        },
        initFullScreen() {
        document.body.classList.toggle("fullscreen-enable");
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(
                Element.ALLOW_KEYBOARD_INPUT
            );
            }
        } else {
            if (document.cancelFullScreen) {
            document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
            }
        }
        },
        agregar_class(){
            this.panelVisible=true
            //this.$refs.buscar.focus();
            let element2 = document.getElementById("panel2");
            element2.classList.add("panel2");
        },
        cerrar_class(){
            this.panelVisible=false;
            /*let element = document.getElementById("panel");
            element.classList.toggle("show");// llamarla con un remove desde afuera (show)*/
            let element2 = document.getElementById("panel2");
            element2.classList.remove("panel2");
        },    
        handleDocumentClick(event) {
            const panel = document.getElementById('panel');
            const busqueda = document.getElementById('busqueda');
            if (this.panelVisible==true) {
                if (panel && !panel.contains(event.target)){
                    this.cerrar_class();
                }
            }else if ((this.panelVisible==false)) {
                if (busqueda && busqueda.contains(event.target)) {
                    // Si el clic ocurrió fuera del panel, cierra el panel
                    this.agregar_class();
                    this.$nextTick(() => {
                        if (this.$refs.searchInput) {
                            this.$refs.searchInput.focus();
                        }
                    });
                }
                
            }
            /*if (panel && !panel.contains(event.target) && this.panelVisible==true) {
                // Si el clic ocurrió fuera del panel, cierra el panel
                this.cerrar_class();
            }else if (busqueda && busqueda.contains(event.target) && this.panelVisible==false) {
                // Si el clic ocurrió fuera del panel, cierra el panel
                this.panelVisible=true
            }*/
        },
        msg(campo){
            Swal.fire({
                //position: "top-end",
                icon: "warning",
                title: "Falta completar el campo "+campo,
                //text: data,
                showConfirmButton: false,
                timer: 1500
            });
        },
        validEmail(email) {
            var re = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,4}$/i;
            var test = re.test(email);
            return test;
        },
        validacion_contacto(bvModalEvent){
            if (this.contacto.nombre =='') {
                this.msg('Nombre');
                return bvModalEvent.preventDefault()
            }
            if (this.contacto.mail =='') {
                this.msg('Correo Electrónico');
                return  bvModalEvent.preventDefault()
            }
            if(!this.validEmail(this.contacto.mail)){
                this.msg('Correo electrónico debe ser válido')
                return bvModalEvent.preventDefault();
            }
            if (this.contacto.asunto =='') {
                this.msg('Asunto');
                return  bvModalEvent.preventDefault()
            }
            if (this.contacto.mensaje =='') {
                this.msg('Mensaje');
                return  bvModalEvent.preventDefault()
            }
            this.enviar();
        },
        enviar(){
            Swal.fire({
                    //position: "top-end",
                    icon: "info",
                    title: "Enviando.....",
                    //text: data,
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                });
            axios.post('/api/contactoweb',this.contacto).then(res=>{
                if(res.data.estado){
                    console.log(res.data.mensaje)
                    console.log(this.contacto)
                    Swal.fire({
                        //position: "top-end",
                        icon: "success",
                        title: "El mensaje fue enviado correctamente.",
                        //text: data,
                        showConfirmButton: false,
                        timer: 1500,
                        timerProgressBar: true,
                    });
                }
            });
            this.$bvModal.hide('vm-contactanos')
        }
    },
    created(){
        axios.get('/api/get_categorias_productos').then(res=>{
            this.categorias=res.data.categorias;
            this.categorias_largo =  Math.round(this.categorias.length/3);
            this.marcas=res.data.marcas;
            this.marcas_largo =  Math.round(this.marcas.length/3);
            this.productos1=res.data.productos;
        });
        document.addEventListener('click', this.handleDocumentClick);
        this.handleResize()
        //v-if="windowWidth>576"
    },
    destroyed() {
        // Remover el manejador de eventos de clic al destruir el componente para evitar fugas de memoria
        document.removeEventListener('click', this.handleDocumentClick);
    },
};
</script>

<template>
    <header id="page-topbar">
        <!-- <div class="bg-light p-2 text-center">
            <h4 class="p-0 m-0">Condiciones de envíos, retiros y plazos de entrega <a  href="http://">Más Información</a></h4>
        </div> -->
        <div class="bg-primary text-center " v-if="windowWidth1<576">
            <a href="/" class="logo logo-dark p-0 m-0">
                <span class="logo-sm">
                <img src="/archivos_base/logoblanco.png" alt height="40" />
                </span>
                <span class="logo-lg">
                <img src="/archivos_base/logoblanco.png" alt height="40" />
                </span>
            </a>
        </div>
        <div :class="windowWidth1>576 ? 'navbar-header bg-primary pt-1':'navbar-header bg-primary'">
            <div class="d-flex p-0 m-0">
                <div class="navbar-brand-box p-0 m-0 pl-2"  v-show="windowWidth1>576">
                    <a href="/" class="logo logo-dark p-0 m-0">
                        <span class="logo-sm">
                        <img src="/archivos_base/logoblanco.png" alt height="40" />
                        </span>
                        <span class="logo-lg">
                        <img src="/archivos_base/logoblanco.png" alt height="40" />
                        </span>
                    </a>
                    <a href="/" class="logo logo-light p-0 m-0">
                        <span class="logo-sm">
                        <img src="/archivos_base/logoblanco.png" alt height="22" />
                        </span>
                        <span class="logo-lg">
                        <img src="/archivos_base/logoblanco.png" alt height="40" />
                        </span>
                    </a>
                </div>
                <div id="categoria">
                    <b-dropdown
                        variant="white"
                        class="dropdown-mega d-none d-lg-block mt-3"
                        toggle-class=""
                        menu-class="dropdown-megamenu"
                    >
                        <template v-slot:button-content>
                            <p class="text-white p-0 m-0">Categorias <i class="mdi mdi-chevron-down text-white"></i></p>
                        </template>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <h5 class="font-size-14 mt-0 text-primary">Categorias</h5>
                                        <ul class="list-unstyled megamenu-list">
                                            <li v-for="(categoria,index1) in categorias" :key="index1" v-show="index1>=0 && index1<=categorias_largo">
                                                <a :href="'/filtrar/categoria/'+categoria.id">{{ categoria.nombre }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-4">
                                        <ul class="list-unstyled megamenu-list">
                                            <li v-for="(categoria,index2) in categorias" :key="index2" v-show="index2>=categorias_largo+1 && index2<=categorias_largo*2">
                                                <a :href="'/filtrar/categoria/'+categoria.id">{{ categoria.nombre }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-4">
                                        <ul class="list-unstyled megamenu-list">
                                            <li v-for="(categoria,index3) in categorias" :key="index3" v-show="index3>=(categorias_largo*2)+1 && index3<=categorias_largo*3">
                                                <a :href="'/filtrar/categoria/'+categoria.id">{{ categoria.nombre }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <h5 class="font-size-14 mt-0 text-primary">Marcas</h5>
                                        <ul class="list-unstyled megamenu-list">
                                            <li v-for="(marca,index4) in marcas" :key="index4" v-show="index4>=0 && index4<=marcas_largo">
                                                <a :href="'/filtrar/marca/'+marca.id">{{ marca.nombre }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-sm-4">
                                        <ul class="list-unstyled megamenu-list">
                                            <li v-for="(marca,index5) in marcas" :key="index5" v-show="index5>=marcas_largo+1 && index5<=marcas_largo*2">
                                                <a :href="'/filtrar/marca/'+marca.id">{{ marca.nombre }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-sm-4">
                                        <ul class="list-unstyled megamenu-list">
                                            <li v-for="(marca,index6) in marcas" :key="index6" v-show="index6>=(marcas_largo*2)+1 && index6<=marcas_largo*3">
                                                <a :href="'/filtrar/marca/'+marca.id">{{ marca.nombre }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <b-button variant="none" href="/filtrar">Ver todo</b-button>
                            </div>
                        </div>
                    </b-dropdown>
                    
                    <div class="search__container" id="busqueda">
                        <input  :class="windowWidth1>576 ? 'search__input':'ml-3 search__input'" type="text" placeholder="Buscar...">
                    </div>
                    <div id="panel" class="search-panel" v-if="panelVisible">
                        <div class="row">
                            <div class="col-md-3">
                                <a href="/" class="logo logo-dark">
                                    <span class="logo-sm">
                                    <img src="/archivos_base/logo.png" alt height="22" />
                                    </span>
                                    <span class="logo-lg">
                                    <img src="/archivos_base/logo.png" alt height="40" />
                                    </span>
                                </a>
                            </div>
                            <div class="col-md-6">
                                <div class="search__container">
                                    <b-form-input
                                        v-model="filter"
                                        type="search"
                                        placeholder="Buscar..."
                                        class="form-control ml-2"
                                        style=" border-radius: 50px;"
                                        ref="searchInput"
                                        ></b-form-input>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <a href="#" @click="cerrar_class" class="float-right">
                                    <i class="bx bx-x"></i>
                                </a>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="offset-md-3 col-md-6">
                                        <h4>Resultados de la búsqueda</h4>
                                        <br>
                                        <a class="font-size-14" v-for="producto in productos2" :key="producto.id" :href="'/producto/'+producto.id">{{ producto.nombre }} - {{ producto.nombre_marca }}<br></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div> 
            <div id="panel2" class="d-flex p-0 m-0">
                <div class="dropdown d-inline-block mt-4">
                    <b-button variant="none" href="/inicio" v-if="autenticado!=''">
                        <p class="text-white p-0 m-0">Admin</p>
                    </b-button>
                    <b-button variant="none" v-b-modal="'vm-contactanos'">
                        <p class="text-white p-0 m-0">Contáctanos</p>
                    </b-button>
                    <b-modal id="vm-contactanos" hide-header hide-footer>
                        <!-- <template #modal-header="{ close }">
                            <h5 class="text-white pb-0 mb-0">Contáctanos</h5>
                            <div class="text-right">
                                <b-button class="text-white text-right" size="sm" variant="none" @click="close()">
                                    <i class="bx bx-x font-size-16"></i>
                                </b-button>
                            </div>
                        </template> -->
                        <div class="row">
                            <div class="col-md-12">
                                <h4 class="text-primary mb-4">Formulario de Contacto</h4>
                                <p><b> Escribenos y en breve nos contactaremos contigo</b></p>
                            </div>
                        </div>
                        <div class="text-left">
                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="12" label="Nombre :" label-for="text">
                                <b-form-input class="border-primary" for="text" placeholder="" v-model="contacto.nombre"></b-form-input>
                            </b-form-group>
                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="12" label="Correo :" label-for="text">
                                <b-form-input class="border-primary" for="text" placeholder="" v-model="contacto.mail"></b-form-input>
                            </b-form-group>
                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="12" label="Asunto :" label-for="text">
                                <b-form-input class="border-primary" for="text" placeholder="" v-model="contacto.asunto"></b-form-input>
                            </b-form-group>
                            <b-form-group id="example text" label-cols-sm="12" label-cols-lg="12" label="Mensaje :" label-for="text">
                                <b-form-textarea
                                    id="textarea"
                                    class="border-primary"
                                    v-model="contacto.mensaje"
                                    rows="3"
                                    max-rows="6"
                                    ></b-form-textarea>
                            </b-form-group>
                            <p class="p-2">Todos los campos son obligaotrios</p>
                            <b-button class="m-0" variant="primary" block @click="validacion_contacto">Enviar</b-button>
                        </div>
                    </b-modal>
                    <!-- <b-button variant="none" href="/inicio" v-if="autenticado!=''">
                        <p class="text-white p-0 m-0" v-if="windowWidth1>1024">Administración</p>
                        <p class="text-white p-0 m-0" v-else><i class="bx bx-cog font-size-16 align-middle"></i></p>
                    </b-button>
                    <b-button variant="none"  href="/logout" v-if="autenticado!=''">
                        <p class="text-white p-0 m-0" v-if="windowWidth1>576"><i class="bx bx-power-off font-size-16 align-middle mr-1"></i> Cerrar Sesión</p>
                        <p class="text-white p-0 m-0" v-else><i class="bx bx-power-off font-size-16 align-middle mr-1"></i></p>
                    </b-button>
                    <b-button variant="none" v-b-modal="'vm-ingresar'" v-else>
                        <p class="text-white p-0 m-0" v-if="windowWidth1>576"> <i class="dripicons-user font-size-16 mr-2 text-white"></i>Ingresar</p>
                        <p class="text-white p-0 m-0" v-else> <i class="dripicons-user font-size-16 mr-2 text-white"></i></p>
                    </b-button>
                    <b-modal id="vm-ingresar" hide-footer>
                        <Login :submiturl='submiturl' :autherror="autherror"/>
                    </b-modal> -->
                </div>
                <div class="dropdown d-inline-block">
                    <button
                        type="button"
                        class="btn header-item noti-icon right-bar-toggle toggle-right"
                        @click="toggleRightSidebar"
                    >
                        <i :class="cartItems.length>0?'bx bx-cart bx-tada text-white toggle-right':'bx bx-cart text-white toggle-right'"></i>
                        <span :class="windowWidth1>576 ?'badge badge-danger badge-pill':'badge badge-danger badge-pill mt-6'" v-if="cartItems.length>0">{{ cartItems.length }}</span>
                    </button>
                    
                </div>
            </div>
        </div>
        <div class="d-lg-none mt-0 bg-white" v-if="!panelVisible">
            <b-dropdown
            variant="black"
            class="dropdown-mega"
            toggle-class=""
            menu-class="dropdown-megamenu mt-0"
            id="categoria2"
            >
                <template v-slot:button-content>
                    Categorias
                    <i class="mdi mdi-chevron-down"></i>
                </template>
                <div class="row">
                    <div class="col-xs-6 p-2">
                        <h5 class="font-size-14 mt-0">Categorias</h5>
                        <ul class="list-unstyled megamenu-list">
                            <li v-for="categoria in categorias" :key="categoria.id">
                                <a :href="'/filtrar/categoria/'+categoria.id">{{ categoria.nombre }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-xs-6 p-2">
                        <h5 class="font-size-14 mt-0">Marcas</h5>
                        <ul class="list-unstyled megamenu-list">
                            <li v-for="marca in marcas" :key="marca.id">
                                <a :href="'/filtrar/marca/'+marca.id">{{ marca.nombre }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-xs-12">
                        <b-button variant="none" href="/filtrar">Ver todo</b-button>
                    </div>
                </div>
            </b-dropdown>
        </div>
    </header>
</template>
<style>
body{
    background-color: #ffffff;
}

.search-panel{
  position: fixed;
  top:0;
  padding: 30px;
  left:0;
  right:0;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 2px #000;
  transition: all .2s;
  transform: translateX(0);
  /*transform: translateX(200%);*/
}
/*.search-panel.show{
  transform: translateX(0);
}*/
/*.search-panel.hidden{
  transform: translateX(200%);
}*/
.panel2{
  visibility: hidden;
}


</style>
